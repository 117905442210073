import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { ToastDisplayComponent } from './components/toast-display/toast-display.component';

@NgModule({
  declarations: [ToastContainerComponent, ToastDisplayComponent],
  imports: [CommonModule, NgbToastModule],
  exports: [ToastContainerComponent],
})
export class ToastsModule {}
