import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { StorageService } from '../../modules/global/auth/services/storage-service';
import { UserService } from '../../modules/global/auth/services/user.service';
import { ImpersonationDataService } from '../../modules/global/impersonation/services/impersonation-data.service';

const IMPERSONATION_HEADER = 'x-impersonation-data';
// import { User } from 'oidc-client';
@Injectable({
  providedIn: 'root',
})
export class RequestService {
 
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private impersonationDataService: ImpersonationDataService,
    private oauthService: OAuthService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  publicQuery<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      return this.http.get<TResponse>('/public/query', {
        responseType: 'json',
        params: { type, data: data ? JSON.stringify(data) : undefined },
        headers: this.getUserHeaders(user),
      });
    });
  }
  publicCommand<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      return this.http.post<TResponse>(
        '/public/command',
        { type, data },
        // { type, data: JSON.stringify(data) },
        {
          responseType: 'json',
          headers: this.getUserHeaders(user),
        }
      );
    });
  }

  mapForCurrentUser<T>(func: (user: UserInfo) => Observable<T>) {
    // take(1) is required here so that the current user 'completes'
    return this.userService.currentUser$.pipe(take(1), switchMap(func));
  }

  basketQuery<TResponse>(type: string, data: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return of<TResponse>();
    }
    return this.mapForCurrentUser((user) => {
      if (!UserService.isAuthenticated(user)) {
        data.userId = UserService.getUserId(user);
      }
      return this.http.get<TResponse>('/basket/query', {
        responseType: 'json',
        params: { type, data: data ? JSON.stringify(data) : undefined },
        headers: this.getUserHeaders(user),
      });
    });
  }

  basketCommand<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      if (!UserService.isAuthenticated(user)) {
        data.userId = UserService.getUserId(user);
      }
      return this.http.post<TResponse>(
        '/basket/command',
        { type, data },
        {
          responseType: 'json',
          headers: this.getUserHeaders(user),
        }
      );
    });
  }

  userQuery<TResponse>(type: string, data: any): Observable<TResponse> {
    if (!isPlatformBrowser(this.platformId)) {
      return of<TResponse>();
    }
    return this.mapForCurrentUser((user) => {
      if (StorageService.isSSR) {
        return of<TResponse>();
      }

      return this.http.get<TResponse>('/user/query', {
        responseType: 'json',
        params: { type, data: data ? JSON.stringify(data) : undefined },
        headers: this.getUserHeaders(user),
      });
    });
  }
  userCommand<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) =>
      this.http.post<TResponse>(
        '/user/command',
        { type, data },
        {
          responseType: 'json',
          headers: this.getUserHeaders(user),
        }
      )
    );
  }

  userDownload<TResponse>(type: string, data: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return of<TResponse>();
    }
    return this.mapForCurrentUser((user) => {
      if (StorageService.isSSR) {
        return of(null);
      }
      return this.http.get('/user/download', {
        responseType: 'blob',
        params: {
          type: type,
          data: data ? JSON.stringify(data) : '',
        },
        headers: this.getUserHeaders(user),
      });
    });
  }

  licenceCommand<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      return this.http.post<TResponse>(
        '/licence/command',
        { type, data },
        { responseType: 'json', headers: this.getUserHeaders(user) }
      );
    });
  }
  private getUserHeaders(user: UserInfo) {
    if (!UserService.isAuthenticated(user)) {
      return undefined;
    }
    const data = {
      Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
    };
    const impersonationData = this.getImpersonatingData(user);
    if (impersonationData) {
      data[IMPERSONATION_HEADER] = escape(JSON.stringify(impersonationData));
    }
    return data;
  }
  private getImpersonatingData(user: UserInfo) {
    if (!UserService.canImpersonate(user)) {
      return undefined;
    }
    return this.impersonationDataService.getData();
  }
}
