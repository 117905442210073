<div class="row justify-content-center mb-4 position-relative">
  <picture class="mt-0 background-picture">
    <source
      srcset="/assets/img/nb-plus/nb-plus-banner.webp"
      type="image/webp"
    />
    <img
      src="/assets/img/nb-plus/nb-plus-banner.jpg"
      alt="NB Plus Background"
      class="img-fluid"
    />
  </picture>
  <div class="col-md-5 pt-3 position-relative">
    <p class="text-center">
      <a [routerLink]="['/nb-plus']">
        <img
          src="../../../assets/img/nb-plus/nb-plus-logo.svg"
          alt="NB Plus logo"
          class="w-75"
        />
      </a>
    </p>
  </div>
  <div class="col-md-7 position-relative">
    <h3 class="text-center text-dark p-4">
      Exclusive NB Plus Book Discount - Buy
      <span class="text-primary">ANY</span> physical book for only
      {{ nbPlusBookPrice | currency : "GBP" }}
    </h3>
    <h4 class="text-center">
      If you are one of our loyal NB Plus subscribers, you will receive a huge
      exclusive discount on all NB Medical Physical Books.<span
        class="text-primary fw-bold"
      >
        Buy any physical book for
        {{ nbPlusBookPrice | currency : "GBP" }}*</span
      >.
    </h4>

    <h5 class="text-center">* Discount applied at checkout</h5>
    <p class="text-center mt-3">
      <a [routerLink]="['/nb-plus']" class="btn btn-{{ typeSlug }}">
        FIND OUT MORE
      </a>
    </p>
  </div>
</div>
