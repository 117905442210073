import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RequestService } from 'src/app/global/services/request.service';

import { BookDetails } from '../dtos/book-details';
import { BookList } from '../dtos/book-list';
import { BookPreview } from '../dtos/book-preview';
import { BookSectionInfo } from '../dtos/book-section-info';
import { BookSummary } from '../dtos/book-summary';

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  constructor(private requestService: RequestService) {}

  getSubjects() {
    return this.requestService.publicQuery<BookSectionInfo[]>(
      'GetBookSubjects',
      {}
    );
  }
  getAllBooks(): Observable<BookSummary[]> {
    return this.requestService
      .publicQuery<BookSummary[]>('GetAllBooks', {})
      .pipe(map((books) => books.map((book) => BookSummary.fromJSON(book))));
  }
  getSubjectBooks(slug: string) {
    return this.requestService.publicQuery<BookList>('GetSubjectBooks', {
      slug,
    });
  }
  getBookDetails(slug: string): Observable<BookDetails> {
    return this.requestService
      .publicQuery<BookDetails>('GetBookDetails', {
        slug,
      })
      .pipe(
        map((book) => {
          return BookDetails.FromJSON(book);
        })
      );
  }

  getBookById(id: string) {
    return this.requestService
      .publicQuery<BookDetails>('GetBookDetailsById', { id })
      .pipe(map((book) => BookDetails.FromJSON(book)));
  }

  getBookPreview(slug: string) {
    return this.requestService.publicQuery<BookPreview>('GetBookPreview', {
      slug,
    });
  }
  getNewerBook(slug: string): Observable<BookDetails> {
    return this.requestService
      .publicQuery<BookDetails>('GetNewerBook', {
        slug,
      })
      .pipe(map((book) => BookDetails.FromJSON(book)));
  }

  getNBPlusBookPrice(): number {
    // could maybe call api in future to further consolidate this, but for now just return a fixed value
    return 30;
  }
}
