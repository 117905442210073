import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RequestService } from 'src/app/global/services/request.service';
import { TagDetail } from './dtos/tag-detail';
import { TagProduct } from './dtos/tag-product';
import { TagSummary } from './dtos/tag-summary';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private requestsService: RequestService) {}

  getAllTags() {
    return this.requestsService
      .publicQuery<TagSummary[]>('GetTags', {})
      .pipe(map((data) => TagSummary.fromJSONArray(data)));
  }

  getTagBySlug(slug: string): Observable<TagDetail | null> {
    return this.requestsService.publicQuery<TagDetail>('GetTagBySlug', {
      id: slug,
    });
  }

  getRelatedContent(id: string, maxItems: number): Observable<TagProduct[]> {
    return this.requestsService.publicQuery<TagProduct[]>('GetRelatedContent', {
      id,
      maxItems,
    });
  }
}
