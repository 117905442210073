import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/global/app-state';
import { RequestService } from 'src/app/global/services/request.service';
import { Address } from 'src/app/modules/global/address/dtos/address';
import { UserAddress } from 'src/app/modules/global/address/dtos/user-address';
import { StorageService } from 'src/app/modules/global/auth/services/storage-service';
import { GTMProductDetailInfo } from 'src/app/modules/global/gtm/dtos/gtm-product-detail-info';
import { GoogleTagManagerService } from 'src/app/modules/global/gtm/services/google-tag-manager.service';
import { ToastService } from 'src/app/modules/global/toasts/services/toast.service';
import { OrganisationProductVoucherSummary } from 'src/app/modules/shared-components/vouchers/dtos/product-voucher-summary';

import { ProductType } from '../../../../shared/dtos/product-type';
import { AddressBookUserAddress } from '../../my-account/dtos/address-book-user-address';
import { MyAccountService } from '../../my-account/services/my-account.service';
import { CompleteOfflinePayment } from '../../order-complete/dtos/complete-offline-payment';
import { BasketData } from '../dtos/basket-data';
import { BasketItem } from '../dtos/basket-item';
import { BasketOperationResult } from '../dtos/basket-operation-result';
import { BasketPayload } from '../dtos/basket-payload';
import { CartPaymentMethod } from '../dtos/cart-payment-method';
import { ContactPreferences } from '../dtos/contact-preferences';
import { CountryData } from '../dtos/country-data';
import { OrganisationBasketData } from '../dtos/organisation-basket-dat';
import { OrganisationBasketPayload } from '../dtos/organisation-basket-payload';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(
    private requestService: RequestService,
    private toastService: ToastService,
    private googleTagManagerService: GoogleTagManagerService,
    private accountService: MyAccountService
  ) {
    AppState.impersonationStateChanged.subscribe((_) => {
      this.retrieveBasketFromServer().pipe(take(1)).subscribe();
      this.getAddressesFromServer().pipe(take(1)).subscribe();
    });
  }
  private basketSubject$ = new BehaviorSubject<BasketData>(null);
  public basket$ = this.basketSubject$.pipe(
    switchMap((basket) => {
      if (basket) {
        return of(basket);
      } else {
        return this.retrieveBasketFromServer();
      }
    }),
    shareReplay(1)
  );

  getUserAddresses(): Observable<UserAddress[]> {
    if (AppState.userAddresses$.value) {
      return AppState.userAddresses$;
    }
    return this.getAddressesFromServer();
  }

  private getAddressesFromServer(): Observable<UserAddress[]> {
    return this.requestService
      .userQuery<AddressBookUserAddress[]>('GetUserAddresses', {})
      .pipe(
        tap((addresses) => AppState.userAddresses$.next(addresses)),
        mergeMap(() => AppState.userAddresses$)
      );
  }

  getCountries(): Observable<CountryData[]> {
    if (AppState.countries$.value) {
      return AppState.countries$;
    }
    return this.requestService
      .publicQuery<CountryData[]>('GetCountries', {})
      .pipe(
        tap((countries) => {
          AppState.countries$.next(countries);
        })
      );
  }

  // getUserBasket() {
  //   if (AppState.basket$.value) {
  //     return AppState.basket$;
  //   }
  //   return this.retrieveBasketFromServer();
  // }

  getUserBasket(): Observable<BasketData> {
    return this.basket$;
  }

  getOrganistionBasket(orgId: string) {
    return this.retrieveOrganiationBasketFromServer(orgId);
  }

  saveOrganisationBasket(
    basket: OrganisationBasketData,
    discountCodes?: string[]
  ) {
    return this.requestService
      .basketCommand<OrganisationBasketPayload>('SaveOrganisationCart', {
        organisationId: basket.organisation.id,
        items: basket.items,
        invoiceReference: basket.invoiceReference,
        paymentMethod: basket.paymentMethod,
        discountCodes: discountCodes,
      })
      .pipe(
        mergeMap((data) =>
          this.mapOrganisationBasketPayload({
            success: true,
            payload: data,
            message: '',
          })
        )
      );
  }

  applyOrganisationDiscounts(
    basket: OrganisationBasketData,
    discountCodes?: string[]
  ) {
    return this.requestService.basketCommand<OrganisationBasketData>(
      'ApplyOrganisationDiscounts',
      {
        organisationId: basket.organisation.id,
        discountCodes: discountCodes,
      }
    );
  }
  mapBasketPayload(
    result: BasketOperationResult,
    onSet?: (basket: BasketData) => void
  ) {
    const basket = this.getNewBasket(result.payload);
    this.basketSubject$.next(basket);
    if (onSet) {
      onSet(basket);
    }
    if (result.message) {
      if (result.success) {
        this.toastService.showSuccessMessage(result.message);
      } else {
        this.toastService.showErrorMessage(result.message);
      }
    }
    return this.basket$;
  }

  private mapOrganisationBasketPayload(
    result: BasketOperationResult,
    onSet?: (basket: OrganisationBasketData) => void
  ) {
    const basket = this.getNewOrganisationBasket(
      result.payload as OrganisationBasketPayload
    );

    if (onSet) {
      onSet(basket);
    }
    if (result.message) {
      if (result.success) {
        this.toastService.showSuccessMessage(result.message);
      } else {
        this.toastService.showErrorMessage(result.message);
      }
    }

    return new BehaviorSubject(basket);
  }

  private getNewBasket(data: BasketPayload): BasketData {
    const basket = Object.assign(new BasketData(), data);
    basket.items = basket.items.map((item) =>
      Object.assign(new BasketItem(), item)
    );
    return basket;
  }

  private getNewOrganisationBasket(
    data: OrganisationBasketPayload
  ): OrganisationBasketData {
    const basket = Object.assign(new OrganisationBasketData(), data);
    basket.items = basket.items.map((item) =>
      Object.assign(new BasketItem(), item)
    );
    return basket;
  }

  addToBasket(
    product: GTMProductDetailInfo,
    productVariantId: string,
    practiceAddress?: Address,
    additionalProducts?: string[]
  ): Observable<BasketOperationResult> {
    return this.requestService
      .basketCommand<BasketOperationResult>('AddItemToCart', {
        productVariantId,
        practiceAddress,
        additionalProducts,
      })
      .pipe(
        tap((_) => this.googleTagManagerService.sendAddToCart(product)),
        tap((data) => this.mapBasketPayload(data))
      );
  }

  getBasketItemFromProductVoucher(
    product: OrganisationProductVoucherSummary,
    quantity: number
  ): BasketItem {
    const price = { price: product.price, vat: 0 };
    return {
      productId: product.productId,
      productType: ProductType.ProductVoucher,
      slug: undefined,
      subtotal: price,
      name: product.productName,
      image: product.imageUrl,
      subtotalPreDiscount: price,
      unitPrice: price,
      discountAmount: { price: 0, vat: 0 },
      productVariantId: undefined,
      quantity,
      validationErrors: undefined,
      hasDiscount: false,
    };
  }

  removeFromBasket(productId: string): Observable<BasketData> {
    return this.requestService
      .basketCommand<BasketOperationResult>('RemoveItemFromCart', { productId })
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  removeItemsFromBasket(productIds: string[]): Observable<BasketData> {
    return this.requestService
      .basketCommand<BasketOperationResult>('RemoveItemsFromCart', {
        productIds,
      })
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  retrieveBasketFromServer(): Observable<BasketData> {
    if (StorageService.isSSR) {
      return of(null);
    }
    return this.requestService
      .basketQuery<BasketPayload>('GetUserCart', {})
      .pipe(
        mergeMap((data) =>
          this.mapBasketPayload({
            success: true,
            payload: data,
            message: '',
          })
        )
      );
  }

  retrieveOrganiationBasketFromServer(
    organisationId: string
  ): Observable<OrganisationBasketData> {
    return this.requestService
      .basketQuery<OrganisationBasketPayload>('GetOrganisationCart', {
        organisationId,
      })
      .pipe(
        mergeMap((data) =>
          this.mapOrganisationBasketPayload({
            success: true,
            payload: data,
            message: '',
          })
        )
      );
  }

  setBillingAddress(address: UserAddress) {
    return this.requestService
      .userCommand<BasketOperationResult>('SetCartBillingAddress', {
        address,
      })
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }
  setShippingAddress(sameAsBilling: boolean, address: UserAddress) {
    return this.requestService
      .userCommand<BasketOperationResult>('SetCartShippingAddress', {
        sameAsBilling,
        address,
      })
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  acceptTermsAndConditions(preferences: ContactPreferences) {
    return this.requestService
      .userCommand<BasketOperationResult>('AcceptTermsAndConditions', {
        preferences,
      })
      .pipe(
        tap(() => {
          this.accountService.getUserInfoFromServer().subscribe();
        }),
        mergeMap((data) => this.mapBasketPayload(data))
      );
  }

  selectPaymentMethod(method: CartPaymentMethod) {
    return this.requestService
      .userCommand<BasketOperationResult>('SelectPaymentMethod', {
        method,
      })
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }
  chooseAnotherMethod() {
    return this.requestService
      .userCommand<BasketOperationResult>('ChooseAnotherPaymentMethod', {})
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  registerTransaction() {
    return this.requestService
      .userCommand<BasketOperationResult>('RegisterTransaction', {})
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  resetTransaction() {
    return this.requestService
      .userCommand<BasketOperationResult>('ResetTransaction', {})
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  cancellationReceived() {
    return this.requestService
      .userCommand<BasketOperationResult>('CancellationReceived', {})
      .pipe(mergeMap((data) => this.mapBasketPayload(data)));
  }

  applyDiscountCode(code: string) {
    const count = AppState.basket$.value?.discounts.length || 0;

    return this.requestService
      .userCommand<BasketOperationResult>('ApplyDiscountCode', { code })
      .pipe(
        mergeMap((data) =>
          this.mapBasketPayload(data, (basket) => {
            if (basket.discounts.length > count) {
              this.toastService.showSuccessMessage('Discount code applied');
            } else {
              this.toastService.showErrorMessage('Could not apply discount');
            }
          })
        )
      );
  }
  removeDiscountCode(code: string) {
    return this.requestService
      .userCommand<BasketOperationResult>('RemoveDiscountCode', { code })
      .pipe(
        mergeMap((data) =>
          this.mapBasketPayload(data, (basket) => {
            this.toastService.showSuccessMessage('Discount code removed');
          })
        )
      );
  }
  completeOrder() {
    return this.requestService
      .userCommand<BasketOperationResult>('CompleteOrder', {})
      .pipe(
        map((data) => {
          const basket = this.getNewBasket(data.payload);
          AppState.basket$.next(basket);
          return data;
        })
      );
  }
  completeInvoiceOrder(invoiceReference: string) {
    return this.requestService
      .userCommand<BasketOperationResult>('CompleteInvoiceOrder', {
        invoiceReference,
      })
      .pipe(
        map((data) => {
          const basket = this.getNewBasket(data.payload);
          AppState.basket$.next(basket);
          return data;
        })
      );
  }

  completeOfflineOrder(request: CompleteOfflinePayment) {
    return this.requestService
      .userCommand<BasketOperationResult>('CompleteOfflineOrder', request)
      .pipe(
        map((data) => {
          const basket = this.getNewBasket(data.payload);
          AppState.basket$.next(basket);
          return data;
        })
      );
  }
}
