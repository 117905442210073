import { ContentTypeInfo } from 'src/app/shared/dtos/content-type-info';
import { IProductSummary, ProductSummary } from 'src/app/shared/dtos/product-summary';
import { ProductType } from 'src/app/shared/dtos/product-type';
import { ProductVariantSummary } from 'src/app/shared/dtos/product-variant-summary';

import { NBPlusBookDiscountStatus } from './nb-plus-book-discount-status';

export interface IBookSummary extends IProductSummary {
  contentTypes: ContentTypeInfo[];
  previewPdf: string;
  requiresShipping: boolean;
  description: string;
  releaseDate: Date;
  released: boolean;

  plusDiscountStatus: NBPlusBookDiscountStatus;
}

export class BookSummary extends ProductSummary implements IBookSummary {
  constructor(
    public id: string,
    public slug: string,
    public name: string,
    public contentTypes: ContentTypeInfo[],
    public image: string | null,
    public imageId: string | null,
    public previewPdf: string,
    public requiresShipping: boolean,
    public description: string,
    public releaseDate: Date,
    public released: boolean,

    public plusDiscountStatus: NBPlusBookDiscountStatus,
    public productType: ProductType,
    public date: Date,
    public variants: ProductVariantSummary[],
    public availableInNBPlus: boolean
  ) {
    super(
      id,
      slug,
      name,
      productType,
      description,
      image,
      imageId,
      date,
      requiresShipping,
      variants,
      availableInNBPlus,
      contentTypes
    );
  }

  static fromJSON(data: any): BookSummary {
    if (!data) return null;
    return new BookSummary(
      data.id,
      data.slug,
      data.name,
      data.contentTypes,
      data.image,
      data.imageId,
      data.previewPdf,
      data.requiresShipping,
      data.description,
      data.releaseDate,
      data.released,
      data.plusDiscountStatus,
      data.productType,
      data.date,
      data.variants,
      data.availableInNBPlus
    );
  }
}
