import { Component, OnInit } from '@angular/core';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-book-type-handler',
  templateUrl: './legacy-book-type-handler.component.html',
  styleUrls: ['./legacy-book-type-handler.component.scss'],
})
export class LegacyBookTypeHandlerComponent implements OnInit {
  constructor(private legacyRedirectionService: LegacyRedirectionService) {}

  ngOnInit(): void {
    this.legacyRedirectionService.redirectTo('/books');
  }
}
