import { Component } from '@angular/core';

import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'nbm-toasts',
  template: ` <div
    class="ngb-toasts toast-container position-fixed top-0 end-0 p-3"
    style="z-index: 1200;"
  >
    <nbm-toast-display
      *ngFor="let toast of toastService.toasts"
      [toast]="toast"
    >
    </nbm-toast-display>
  </div>`,
})
export class ToastContainerComponent {
  constructor(public toastService: ToastService) {}
}
