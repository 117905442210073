import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { RequestService } from 'src/app/global/services/request.service';

import { BasketService } from './basket.service';

@Injectable({
  providedIn: 'root',
})
export class CopyBasketOnLoginService {
  async copyAnonymousItemsToUser(userId: string) {
    await this.requestService
      .userCommand('CopyAnonymousItemsToCart', {
        userId,
      })
      .toPromise();

    await this.basketService
      .retrieveBasketFromServer()
      .pipe(take(1))
      .toPromise();
  }

  constructor(
    private requestService: RequestService,
    private basketService: BasketService
  ) {}
}
