import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-webinar-type-handler',
  templateUrl: './legacy-webinar-type-handler.component.html',
  styleUrls: ['./legacy-webinar-type-handler.component.scss'],
})
export class LegacyWebinarTypeHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const category = params.Website_Category;
      switch (category) {
        case 'GP Update':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/hot-topics-gp-update'
          );
        case 'Urgent Care':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/urgent-care'
          );
        case 'Diabetes':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/diabetes'
          );
        case 'Cancer':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/cancer'
          );
        case 'Womens Health':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/womens-health'
          );
        case 'Primary Care Nurse':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/nurse'
          );
        case 'Resilience':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/resilience'
          );
        case 'Trainee':
          return this.legacyRedirectionService.redirectTo(
            '/webinars/subject/gp-trainee'
          );
        case 'Free Webinars':
          return this.legacyRedirectionService.redirectTo('/webinars/clinics');
        default:
          return this.legacyRedirectionService.redirectTo('/webinars');
      }
    });
  }
}
