export enum BasketState {
  RequiresItems = 'RequiresItems',
  RequiresLogin = 'RequiresLogin',
  RequiresBillingAddress = 'RequiresBillingAddress',
  RequiresShippingAddress = 'RequiresShippingAddress',
  RequiresShippingOption = 'RequiresShippingOption',
  TermsAndConditionsRequired = 'TermsAndConditionsRequired',
  ChoosePaymentMethod = 'ChoosePaymentMethod',
  ReadyToPay = 'ReadyToPay',
  Paying = 'Paying',
  CanComplete = 'CanComplete',
  Invalid = 'Invalid',
}
