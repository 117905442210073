import { Injectable, TemplateRef } from '@angular/core';

import { ToastInfo } from '../objects/toast-info';
import { ToastOptions } from '../objects/toast-options';

const DEFAULT_DELAY = 7500;
@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: ToastOptions = {}) {
    const info = { textOrTpl, options };
    this.toasts.push(info);
    return info;
  }

  showSuccessMessage(text: string, options: ToastOptions = {}) {
    options.className = 'bg-success text-light';
    options.delay = options.delay ?? DEFAULT_DELAY;
    options.autoHide = options.autoHide === undefined ? true : options.autoHide;
    this.show(text, options);
  }

  showErrorMessage(text: string, options: ToastOptions = {}) {
    options.className = 'bg-danger text-light';
    options.delay = options.delay ?? DEFAULT_DELAY;
    options.autoHide = options.autoHide === undefined ? true : options.autoHide;
    this.show(text, options);
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
