import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  static scriptType = 'application/ld+json';
  private knownElements: HTMLScriptElement[] = [];
  static websiteSchema(url?: string, name?: string) {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: url || 'https://www.nbmedical.com',
      name: name || 'NB Medical Education',
    };
  }

  static orgSchema() {
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'https://www.nbmedical.com',
      name: 'NB Medical Education',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+44(0)1916031872',
        contactType: 'Customer service',
      },
    };
  }

  constructor(@Inject(DOCUMENT) private _document: Document) {
    // pick up any scripts that were added by SSR
    const scripts = _document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (script.type === JsonLdService.scriptType) {
        this.knownElements.push(script);
      }
    }
  }

  removeExisting() {
    for (const scriptElement of this.knownElements) {
      if (scriptElement.parentElement)
        scriptElement.parentElement.removeChild(scriptElement);
    }
    this.knownElements.splice(0); // empty list
  }

  insertSchema(schema: Record<string, any>, key: string): void {
    // if already exists, stop
    if (
      this.knownElements.some((x) => x.getAttribute('data-json-ld') === key)
    ) {
      return;
    }

    const script = this._document.createElement('script');
    script.setAttribute('data-json-ld', key);
    script.type = JsonLdService.scriptType;
    script.text = JSON.stringify(schema);
    this._document.head.appendChild(script);
    this.knownElements.push(script);
  }
}
