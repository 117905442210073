export const simpleRedirects: { previous: string; current: string }[] = [
  {
    previous: 'NBHome',
    current: '',
  },
  {
    previous: 'home',
    current: '',
  },
  {
    previous: 'NBGPUpdateCourses',
    current: 'courses',
  },
  {
    previous: 'NBCoursesByCity',
    current: 'courses/location',
  },
  {
    previous: 'NBMEETINGS',
    current: 'bespoke/access',
  },
  {
    previous: 'NBMeetings',
    current: 'bespoke/access',
  },
  {
    previous: 'nbmeetings',
    current: 'bespoke/access',
  },
  {
    previous: 'NBBotulinum',
    current: 'botulinum-toxin-course',
  },
  {
    previous: 'NBMinorsurgery',
    current: 'minor-surgery-and-joint-injection-course',
  },
  {
    previous: 'NBMusculoSkeletal',
    current: 'musculo-skeletal-course',
  },
  {
    previous: 'NBArora',
    current: 'arora-medical-education-for-the-mrgcp-exam-course',
  },
  {
    previous: 'NBGPUpdateBooklets',
    current: 'books',
  },
  {
    previous: 'NBGPUpdateWebinars',
    current: 'webinars',
  },
  {
    previous: 'NBSchedule',
    current: 'webinars/live',
  },
  {
    previous: 'downloads',
    current: 'kiss',
  },
  {
    previous: 'NBPodcasts',
    current: 'podcast',
  },
  {
    previous: 'nbPodcasts',
    current: 'podcast',
  },
  {
    previous: 'NBWhatWeDo',
    current: 'company-information/about-us',
  },
  {
    previous: 'affiliated_partners',
    current: 'partners',
  },
  {
    previous: 'NBInternational',
    current: 'international-links',
  },
  {
    previous: 'NBTestimonials',
    current: 'testimonials',
  },
  {
    previous: 'NBContact',
    current: 'contact-us',
  },
  {
    previous: 'NBCourseAccess',
    current: 'bespoke/access',
  },
  {
    previous: 'NBListAppraisalEssentials',
    current: 'appraisal-essentials',
  },
  {
    previous: 'NBBasket',
    current: 'basket',
  },
  {
    previous: 'NBTermsAndConditionsOfUse',
    current: 'terms-and-conditions-of-use',
  },
  {
    previous: 'NBTermsAndConditionsOfSupply',
    current: 'terms-and-conditions-of-supply',
  },
  {
    previous: 'NBAcceptableUsePolicy',
    current: 'acceptable-use-policy',
  },
  {
    previous: 'NBPrivacyAndCookiesPolicy',
    current: 'privacy-and-cookies-policy',
  },
  {
    previous: 'nb-app',
    current: 'nb-plus',
  },
  {
    previous: 'NBApp',
    current: 'nb-plus',
  },
  {
    previous: 'NBInstructions',
    current: 'nb-plus',
  },
  {
    previous: 'NBNotices',
    current: 'notices',
  },
  {
    previous: 'NBCoursePreviews',
    current: 'courses/preview',
  },
  {
    previous: 'outOfOffice',
    current: 'out-of-office',
  },
  {
    previous: 'NBVacancies',
    current: 'careers',
  },
  {
    previous: 'NBSiteMap',
    current: '',
  },
  {
    previous: 'international-links',
    current: 'nb-global',
  },
  {
    previous: 'partners/gp-horizon',
    current: 'partners/nb-on-call',
  },
];
