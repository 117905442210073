import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JumbotronComponent } from 'src/app/core-ui/components/jumbotron/jumbotron.component';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { BooksService } from 'src/app/modules/routed-features/books/services/books.service';

@Component({
  selector: 'nbm-nb-plus-book-offer-promo',
  templateUrl: './nb-plus-book-offer-promo.component.html',
  styleUrls: ['./nb-plus-book-offer-promo.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule],
})
export class NbPlusBookOfferPromoComponent {
  @Input()
  typeSlug: string;

  constructor(private bookService: BooksService) {}

  get nbPlusBookPrice(): number {
    return this.bookService.getNBPlusBookPrice();
  }
}
