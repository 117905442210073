import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/core-ui/dtos/breadcrumb';

@Injectable({ providedIn: 'root' })
export class BreadcrumbDataResolver {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Breadcrumb[] | Observable<Breadcrumb[]> | Promise<Breadcrumb[]> {
    return this.createBreadcrumbs(route.root);
  }

  private createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.url
        .map((segment) => encodeURIComponent(segment.path))
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const title = child.routeConfig.data
        ? child.routeConfig.data.title || ''
        : '';
      const metaTitle = child.routeConfig.data
        ? child.routeConfig.data.metaTitle || undefined
        : undefined;
      const description = child.routeConfig.data
        ? child.routeConfig.data.description || ''
        : '';
      const placeHolder = child.routeConfig.data?.placeholder;
      if (placeHolder || title || description) {
        breadcrumbs.push({
          title,
          metaTitle: metaTitle,
          url,
          description,
          placeHolder,
        });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
