<a
  *ngIf="routerLink; else actionButton"
  [routerLink]="routerLink"
  [fragment]="fragment"
  [class]="'btn  text-uppercase btn-' + btnType + ' ' + additionalClasses"
  [ngClass]="{ 'btn-lg': largeButton }"
  [nbmProcessingButton]="processing"
  [originalText]="originalText"
>
</a>
<ng-template #actionButton>
  <button
    [class]="'btn text-uppercase btn-' + btnType + ' ' + additionalClasses"
    [ngClass]="{ 'btn-lg': largeButton }"
    (click)="clicked.emit()"
    [nbmProcessingButton]="processing"
    [originalText]="originalText"
  ></button>
</ng-template>
