import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

import { RequestService } from '../../../../global/services/request.service';
import { GetLegacyBlogResult } from '../dtos/get-legacy-blog-result';
import { GetLegacyPackageResult } from '../dtos/get-legacy-package-result';

@Injectable({
  providedIn: 'root',
})
export class LegacyRedirectionService {
  constructor(
    private requestService: RequestService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}
  getPackage(id: string) {
    return this.requestService.publicQuery<GetLegacyPackageResult>(
      'GetLegacyPackage',
      { id }
    );
  }
  getBlog(id: string) {
    return this.requestService.publicQuery<GetLegacyBlogResult>(
      'GetLegacyBlog',
      { id }
    );
  }
  redirectTo(redirectTo: string) {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.redirect(301, redirectTo);
      this.response.end();
      return;
    } else {
      return this.router.navigateByUrl(redirectTo);
    }
  }
  createUrlTreeOrRedirect(redirectTo: string) {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.redirect(301, redirectTo);
      this.response.end();
      return;
    } else {
      return this.router.createUrlTree([redirectTo]);
    }
  }
}
