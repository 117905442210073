import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ImpersonationService } from '../../services/impersonation.service';

@Component({
  selector: 'nbm-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss'],
})
export class ImpersonateUserComponent {
  state: ImpersonateUserState = ImpersonateUserState.CheckEmail;
  showCreatePrompt = false;
  showCreateError = false;
  createError: string;
  showImpersonateValidationError = false;
  showCreateValidationError = false;
  errors: string;

  get isCheckEmail() {
    return this.state === ImpersonateUserState.CheckEmail;
  }
  get isCreateUser() {
    return this.state === ImpersonateUserState.CreateUser;
  }

  constructor(
    private formBuilder: FormBuilder,
    private impersonationService: ImpersonationService,
    private modal: NgbActiveModal
  ) {}

  impersonateForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });
  createUserForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
  });

  tryImpersonate() {
    if (this.impersonateForm.valid) {
      this.showImpersonateValidationError = false;
      this.impersonationService
        .tryImpersonate(this.impersonateForm.value.email)
        .subscribe((result) => {
          if (result.success) {
            this.modal.close(result.userSummary);
          } else {
            this.showCreatePrompt = true;
          }
        });
    } else {
      this.showImpersonateValidationError = true;
    }
  }

  tryCreateUser() {
    if (this.createUserForm.valid) {
      this.showCreateError = false;
      this.showCreateValidationError = false;
      this.impersonationService
        .createUserToImpersonate(this.createUserForm.value)
        .subscribe((result) => {
          if (result.success) {
            this.modal.close(result.userSummary);
          } else {
            this.createError = result.message;
            this.showCreateError = true;
          }
        });
    } else {
      this.showCreateValidationError = true;
    }
  }
  switchToCreateUser() {
    this.createUserForm.patchValue({ email: this.impersonateForm.value.email });
    this.state = ImpersonateUserState.CreateUser;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.isCheckEmail) {
        this.tryImpersonate();
      } else {
        this.tryCreateUser();
      }
    }
  }
}
enum ImpersonateUserState {
  CheckEmail = 'CheckEmail',
  CreateUser = 'CreateUser',
}
