<div class="form-inline mb-2 mb-lg-0">
  <input
    class="form-control rounded-pill ignore-validity d-block w-100"
    type="search"
    placeholder="Search"
    aria-label="Search"
    [(ngModel)]="model"
    [ngbTypeahead]="search"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    (selectItem)="onSelected($event)"
  />
</div>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="getWords(t)"></ngb-highlight>
  <ng-container *ngIf="r.formattedType">
    <br />
    <small>
      {{ r.formattedType }}
    </small>
  </ng-container>
</ng-template>
