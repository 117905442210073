<div class="loader">
  <section
    class="loading d-flex justify-content-center flex-column align-items-center"
    [style.min-height]="minHeight"
    id="wait"
  >
    <div class="lds-ripple" id="ripple">
      <div class="border-white"></div>
      <div class="border-white"></div>
    </div>
    <h2 class="text-white">
      {{ displayText }}
    </h2>
  </section>
</div>
