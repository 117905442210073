import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlackFridayComponent } from './black-friday.component';
import { RouterModule } from '@angular/router';
import { PlainButtonComponent } from '../../../core-ui/components/plain-button/plain-button.component';

@NgModule({
  declarations: [BlackFridayComponent],
  imports: [CommonModule, RouterModule, PlainButtonComponent],
  exports: [BlackFridayComponent],
})
export class BlackFridayModule {}
