import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RemoteContentModule } from '../../../modules/global/remote-content/remote-content.module';

import { HeaderActionLinkComponent } from './header-action-link/header-action-link.component';
import { HeaderExternalLinkComponent } from './header-external-link/header-external-link.component';
import { HeaderNavigationComponent } from './header-navigation.component';
import { HeaderPathLinkComponent } from './header-path-link/header-path-link.component';

@NgModule({
  declarations: [
    HeaderNavigationComponent,
    HeaderPathLinkComponent,
    HeaderActionLinkComponent,
    HeaderExternalLinkComponent,
  ],
  imports: [CommonModule, RouterModule, NgbDropdownModule, RemoteContentModule],
  exports: [
    HeaderNavigationComponent,
    HeaderPathLinkComponent,
    HeaderActionLinkComponent,
    HeaderExternalLinkComponent,
  ],
})
export class HeaderNavigationModule {}
