import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-course-location-handler',
  templateUrl: './legacy-course-location-handler.component.html',
  styleUrls: ['./legacy-course-location-handler.component.scss'],
})
export class LegacyCourseLocationHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const location = ((params.loc as string) || '').toLocaleLowerCase();

      this.legacyRedirectionService.redirectTo(
        `/face-to-face-courses/location/${location}`
      );
    });
  }
}
