import { Injectable } from '@angular/core';

import { BasketData } from '../../../routed-features/basket/dtos/basket-data';
import { OrderSummary } from '../../../routed-features/order-complete/dtos/order-summary';
import { StorageService } from '../../auth/services/storage-service';
import { GTMEnterCheckoutInfo } from '../dtos/gtm-enter-checkout-info';
import { GTMProductCategory } from '../dtos/gtm-product-category.enum';
import { GTMProductClickInfo } from '../dtos/gtm-product-click-info';
import { GTMProductDetailInfo } from '../dtos/gtm-product-detail-info';
import { GTMProductImpressionInfo } from '../dtos/gtm-product-impression-info';
import { CurrentListService } from './current-list.service';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(private currentListService: CurrentListService) {}
  sent: number[] = [];
  sendPageView(url: string, title: string) {
    this.pushToDataLayer('pageView', { page: { path: url, title } });
  }

  sendImpressionView(products: GTMProductImpressionInfo[]) {
    this.pushToDataLayer('eec.impressionView', {
      ecommerce: {
        impressions: products.map((y, i) => ({
          name: y.name,
          id: y.id,
          position: i + 1,
          list: this.currentListService.currentList,
        })),
      },
    });
  }
  sendImpressionClick(
    product: GTMProductClickInfo,
    index: number,
    category: GTMProductCategory
  ) {
    this.pushToDataLayer('eec.impressionClick', {
      ecommerce: {
        click: {
          actionField: {
            list: this.currentListService.currentList,
          },
          products: [
            {
              name: product.name,
              id: product.id,
              position: index + 1,
              category,
            },
          ],
        },
      },
    });
  }
  sendDetailView(product: GTMProductDetailInfo, category: GTMProductCategory) {
    this.pushToDataLayer('eec.detail', {
      ecommerce: {
        detail: {
          actionField: {
            list: this.currentListService.currentList,
          },
          products: [
            {
              name: product.name,
              id: product.id,
              category,
            },
          ],
        },
      },
    });
  }
  sendAddToCart(product: GTMProductDetailInfo, custom?: any) {
    custom = custom || {};
    this.pushToDataLayer('eec.add', {
      ecommerce: {
        add: {
          actionField: {
            list: this.currentListService.currentList,
          },
          products: [
            {
              name: product.name,
              id: product.id,
              ...custom,
            },
          ],
        },
      },
    });
  }
  sendEnterCheckout(basket: BasketData) {
    this.pushToDataLayer('eec.checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products: this.getBasketProducts(basket),
        },
      },
    });
  }
  getBasketProducts(basket: BasketData) {
    if (!basket) {
      return undefined;
    }
    return basket.items.map((product) => ({
      name: product.name,
      id: product.productId,
      category: product.productType,
      quantity: 1,
    }));
  }

  sendEnterCheckoutForGroup(data: GTMEnterCheckoutInfo) {
    this.pushToDataLayer('eec.checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products: [
            {
              name: data.name,
              id: data.id,
              category: data.productType,
              quantity: 1,
              dimension12: 'groupBooking',
            },
          ],
        },
      },
    });
  }
  sendCheckoutStep(step: number, basket: BasketData) {
    if (!basket || this.sent.some((x) => x === step)) {
      return;
    }
    this.pushToDataLayer('eec.checkout', {
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products: this.getBasketProducts(basket),
        },
      },
    });
    this.sent.push(step);
  }

  sendPurchase(order: OrderSummary) {
    this.pushToDataLayer('eec.purchase', {
      ecommerce: {
        currencyCode: 'GBP',
        purchase: {
          actionField: {
            id: order.id,
            affiliation: 'NB Medical',
            revenue: order.total.total,
            tax: order.total.vat,
            shipping: order.shipping?.price?.total || 0,
            coupon: order.discountCodes.join(', '),
          },
          products: order.items.map((item) => ({
            id: item.productId,
            name: item.productName,
            category: item.productType,
            quantity: 1,
            price: item.subtotal.total,
          })),
        },
      },
    });
  }

  pushOptimizeActivate() {
    this.pushToDataLayer('optimize.activate');
  }

  private pushToDataLayer(eventName: string, data?: any) {
    if (StorageService.isSSR) {
      return;
    }
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  }
}
