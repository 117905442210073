export class TagSummary {
  id: string;
  text: string;
  slug: string;
  itemsLinked: number;
  firstLetter: string;
  private _searchText: string;
  private _searchTitle: string;

  constructor(id: string, text: string, slug: string, itemsLinked: number) {
    this.id = id;
    this.text = text;
    this.slug = slug;
    this._searchText = text.toLowerCase();
    this._searchTitle = text.toLowerCase();
    this.itemsLinked = itemsLinked;
    this.firstLetter = text[0].toUpperCase();
  }

  get routerLink(): string[] {
    return ['/content-index', this.id];
  }

  earliestMatch(words: string[]): number | undefined {
    let result: number | undefined = undefined;
    for (let word of words) {
      let index = this._searchText.indexOf(word);
      if (index > -1) {
        if (result === undefined || index < result) {
          result = index;
        }
      }
    }

    return result;
  }

  searchMatch(query?: string): boolean {
    if (!query) return true; // no query, so it matches
    const words = (query || '')
      .split(' ')
      .map((x) => x.toLowerCase())
      .filter((x) => x.length > 0);

    return words.every((word) => this._searchTitle.indexOf(word) >= 0);
  }

  static fromJSON(json: any): TagSummary {
    return new TagSummary(json.id, json.text, json.slug, json.itemsLinked);
  }

  static fromJSONArray(jsonArray: any[]): TagSummary[] {
    return jsonArray.map((json) => TagSummary.fromJSON(json));
  }
}
