import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { RequestService } from '../../../../global/services/request.service';
import { ImpersonationResult } from '../dtos/impersonation-result';
import { ImpersonationDataService } from './impersonation-data.service';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  constructor(
    private requestService: RequestService,
    private impersonationDataService: ImpersonationDataService
  ) {}

  tryImpersonate(email: string, reload = true) {
    return this.requestService
      .userCommand<ImpersonationResult>('TryImpersonate', {
        email,
      })
      .pipe(
        tap((result) => {
          if (result.success) {
            this.impersonationDataService.setData(result.userSummary, reload);
          }
        })
      );
  }
  refreshImpersonationData(): Promise<boolean> {
    try {
      const email = this.impersonationDataService.getData()?.email;
      if (!email) {
        return Promise.resolve(true);
      }
      return this.tryImpersonate(email, false)
        .toPromise()
        .then(() => true);
    } catch {
      console.error('Error refreshing impersonation data');
      return Promise.resolve(false);
    }
  }
  createUserToImpersonate(request: any) {
    return this.requestService
      .userCommand<ImpersonationResult>('CreateUserToImpersonate', request)
      .pipe(
        tap((result) => {
          if (result.success) {
            this.impersonationDataService.setData(result.userSummary);
          }
        })
      );
  }
}
