import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-course-subject-handler',
  templateUrl: './legacy-course-subject-handler.component.html',
  styleUrls: ['./legacy-course-subject-handler.component.scss'],
})
export class LegacyCourseSubjectHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const subject = (params.subject as string) || '';
      if (!subject) {
        return this.legacyRedirectionService.redirectTo(
          '/face-to-face-courses'
        );
      }
      return this.legacyRedirectionService.redirectTo(
        '/face-to-face-courses/subject/' + subject
      );

      // this.legacyRedirectionService.getBlog(subject).subscribe((result) => {
      //   if (result) {
      //     return this.legacyRedirectionService.redirectTo(
      //       `/blog/${result.slug}`
      //     );
      //   }
      //   return this.legacyRedirectionService.redirectTo('/blog');
      // });
    });
  }
}
