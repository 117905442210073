import { Component } from '@angular/core';
import {
  NgbModule,
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageRouterService } from 'src/app/global/services/page-router.service';

import { SearchData } from '../../dtos/search-data';
import { HeaderSearchService } from './header-search.service';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'nbm-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbTypeahead, NgbModule, FormsModule],
})
export class HeaderSearchComponent {
  model: any;

  constructor(
    private headerSearchService: HeaderSearchService,
    private productRouter: PageRouterService
  ) {}

  search: OperatorFunction<string, readonly SearchData[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.headerSearchService.search(term))
    );

  formatter = (x: SearchData) => x.name;

  onSelected(selected: NgbTypeaheadSelectItemEvent<SearchData>) {
    this.productRouter.routeToPage(selected.item.type, selected.item.slug);
  }
  getWords(term: string) {
    return this.headerSearchService.getWords(term);
  }
}
