import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { caseInsensitiveMatcher } from './global/case-insensitive-matcher';
import { BreadcrumbDataResolver } from './global/resolvers/breadcrumb-data.resolver';
import { CmsDataResolver } from './global/resolvers/cms-data.resolver';
import { legacyRedirectionRoutes } from './modules/global/legacy-redirection/legacy-redirection-routes';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/routed-features/home/home.module').then(
            (x) => x.HomeModule
          ),
      },
      {
        // path: 'auth',
        matcher: caseInsensitiveMatcher('auth'),
        loadChildren: () =>
          import('./modules/routed-features/auth-ui/auth-ui.module').then(
            (x) => x.AuthUiModule
          ),
      },
      {
        // path: 'subjects',
        matcher: caseInsensitiveMatcher('subjects'),
        loadChildren: () =>
          import('./modules/routed-features/subjects/subjects.module').then(
            (x) => x.SubjectsModule
          ),
      },
      {
        // path: 'international-links',
        matcher: caseInsensitiveMatcher('nb-global'),
        loadChildren: () =>
          import('./modules/routed-features/nb-global/nb-global.module').then(
            (x) => x.NBGlobalModule
          ),
      },
      {
        // path: 'bespoke',
        matcher: caseInsensitiveMatcher('bespoke'),
        loadChildren: () =>
          import('./modules/routed-features/bespoke/bespoke.module').then(
            (x) => x.BespokeModule
          ),
      },
      {
        // path: 'partners',
        matcher: caseInsensitiveMatcher('partners'),
        loadChildren: () =>
          import('./modules/routed-features/partners/partners.module').then(
            (x) => x.PartnersModule
          ),
      },
      {
        // path: 'recommended',
        matcher: caseInsensitiveMatcher('recommended'),
        loadChildren: () =>
          import(
            './modules/routed-features/courses/pages/recommended-courses/recommended-courses.module'
          ).then((x) => x.RecommendedCoursesModule),
      },
      {
        // path: 'courses',
        matcher: caseInsensitiveMatcher('face-to-face-courses'),
        loadChildren: () =>
          import('./modules/routed-features/courses/courses.module').then(
            (x) => x.CoursesModule
          ),
      },
      {
        // path: 'courses',
        matcher: caseInsensitiveMatcher('courses'),
        loadChildren: () =>
          import(
            './modules/routed-features/online-courses/online-courses.module'
          ).then((x) => x.OnlineCoursesModule),
      },
      {
        // path: 'online-courses',
        matcher: caseInsensitiveMatcher('online-courses'),
        loadChildren: () =>
          import(
            './modules/routed-features/online-courses-holding/online-courses-holding.module'
          ).then((x) => x.OnlineCoursesHoldingModule),
      },
      {
        // path: 'clinics',
        matcher: caseInsensitiveMatcher('clinics'),
        loadChildren: () =>
          import('./modules/routed-features/clinics/clinics.module').then(
            (x) => x.ClinicsModule
          ),
      },
      {
        // path: 'books',
        matcher: caseInsensitiveMatcher('books'),
        loadChildren: () =>
          import('./modules/routed-features/books/books.module').then(
            (x) => x.BooksModule
          ),
      },
      {
        // path: 'webinars',
        matcher: caseInsensitiveMatcher('webinars'),
        loadChildren: () =>
          import('./modules/routed-features/webinars/webinars.module').then(
            (x) => x.WebinarsModule
          ),
      },
      {
        // path: 'appraisal-essentials',
        matcher: caseInsensitiveMatcher('appraisal-essentials'),
        loadChildren: () =>
          import(
            './modules/routed-features/appraisal-essentials/appraisal-essentials.module'
          ).then((x) => x.AppraisalEssentialsModule),
      },
      {
        // path: 'cpd-modules',
        matcher: caseInsensitiveMatcher('cpd-modules'),
        loadChildren: () =>
          import(
            './modules/routed-features/cpd-modules/cpd-modules.module'
          ).then((x) => x.CpdModulesModule),
      },
      {
        // path: 'kiss',
        matcher: caseInsensitiveMatcher('kiss'),
        loadChildren: () =>
          import('./modules/routed-features/kiss/kiss.module').then(
            (x) => x.KissModule
          ),
      },
      {
        // path: 'blog',
        matcher: caseInsensitiveMatcher('blog'),
        loadChildren: () =>
          import('./modules/routed-features/blog/blog.module').then(
            (x) => x.BlogModule
          ),
      },
      {
        // path: 'podcast',
        matcher: caseInsensitiveMatcher('podcast'),
        loadChildren: () =>
          import('./modules/routed-features/podcast/podcast.module').then(
            (x) => x.PodcastModule
          ),
      },
      {
        // path: 'out-of-office',
        matcher: caseInsensitiveMatcher('out-of-office'),
        loadChildren: () =>
          import(
            './modules/routed-features/out-of-office/out-of-office.module'
          ).then((x) => x.OutOfOfficeModule),
      },
      {
        // path: 'notices',
        matcher: caseInsensitiveMatcher('notices'),
        loadChildren: () =>
          import('./modules/routed-features/notices/notices.module').then(
            (x) => x.NoticesModule
          ),
      },
      // {
      //   // path: 'careers',
      //   matcher: caseInsensitiveMatcher('careers'),
      //   loadChildren: () =>
      //     import('./modules/routed-features/careers/careers.module').then(
      //       (x) => x.CareersModule
      //     ),
      // },
      {
        // path: 'nb-plus',
        matcher: caseInsensitiveMatcher('nb-plus'),
        loadChildren: () =>
          import('./modules/routed-features/nb-plus/nb-plus.module').then(
            (x) => x.NbPlusModule
          ),
      },
      {
        // path: 'nb-plus-fixed-term',
        matcher: caseInsensitiveMatcher('nb-plus-fixed-term'),
        loadChildren: () =>
          import(
            './modules/routed-features/nb-plus-fixed-term/nb-plus-fixed-term.module'
          ).then((x) => x.NbPlusFixedTermModule),
      },
      // {
      //   // path: 'nb-app',
      //   matcher: caseInsensitiveMatcher('nb-app'),
      //   loadChildren: () =>
      //     import('./modules/routed-features/nb-app/nb-app.module').then(
      //       (x) => x.NbAppModule
      //     ),
      // },

      {
        // path: 'company-information/about-us',
        matcher: caseInsensitiveMatcher('company-information/about-us'),
        loadChildren: () =>
          import(
            './modules/routed-features/company-information/company-information.module'
          ).then((x) => x.CompanyInformationModule),
      },
      {
        // path: 'added-to-cart',
        matcher: caseInsensitiveMatcher('added-to-cart'),
        loadChildren: () =>
          import(
            './modules/routed-features/added-to-cart/added-to-cart.module'
          ).then((x) => x.AddedToCartModule),
      },
      {
        // path: 'basket',
        matcher: caseInsensitiveMatcher('basket'),
        loadChildren: () =>
          import('./modules/routed-features/basket/basket.module').then(
            (x) => x.BasketModule
          ),
      },
      {
        // path: 'checkout',
        matcher: caseInsensitiveMatcher('checkout'),
        loadChildren: () =>
          import('./modules/routed-features/checkout/checkout.module').then(
            (x) => x.CheckoutModule
          ),
      },
      {
        // path: 'order-complete',
        matcher: caseInsensitiveMatcher('order-complete'),
        loadChildren: () =>
          import(
            './modules/routed-features/order-complete/order-complete.module'
          ).then((x) => x.OrderCompleteModule),
      },
      {
        // path: 'my-account',
        matcher: caseInsensitiveMatcher('my-account'),
        loadChildren: () =>
          import('./modules/routed-features/my-account/my-account.module').then(
            (x) => x.MyAccountModule
          ),
      },
      {
        // path: 'contact-us',
        matcher: caseInsensitiveMatcher('contact-us'),
        loadChildren: () =>
          import('./modules/routed-features/contact-us/contact-us.module').then(
            (x) => x.ContactUsModule
          ),
      },
      {
        // path: 'testimonials',
        matcher: caseInsensitiveMatcher('testimonials'),
        loadChildren: () =>
          import(
            './modules/routed-features/testimonials/testimonials.module'
          ).then((x) => x.TestimonialsModule),
      },
      {
        // path: 'vouchers',
        matcher: caseInsensitiveMatcher('vouchers'),
        loadChildren: () =>
          import('./modules/routed-features/vouchers/vouchers.module').then(
            (x) => x.VouchersModule
          ),
      },
      {
        // path: 'faqs',
        matcher: caseInsensitiveMatcher('faqs'),
        loadChildren: () =>
          import('./modules/routed-features/faqs/faqs.module').then(
            (x) => x.FAQsModule
          ),
      },
      {
        matcher: caseInsensitiveMatcher('cpd'),
        loadChildren: () =>
          import('./modules/routed-features/cpd/cpd.module').then(
            (x) => x.CpdModule
          ),
      },
      {
        matcher: caseInsensitiveMatcher('content-explorer'),
        loadChildren: () =>
          import('./modules/routed-features/tags/tags.module').then(
            (x) => x.TagsModule
          ),
      },
      // {
      //   // path: 'job-board',
      //   matcher: caseInsensitiveMatcher('job-board'),
      //   loadChildren: () =>
      //     import('./modules/routed-features/job-board/job-board.module').then(
      //       (x) => x.JobBoardModule
      //     ),
      // },
      ...legacyRedirectionRoutes,
      {
        path: '',
        loadChildren: () =>
          import('./modules/routed-features/cms/cms.module').then(
            (x) => x.CmsModule
          ),
      },
    ],
    resolve: {
      breadcrumbData: BreadcrumbDataResolver,
      pageEnhancementInfo: CmsDataResolver,
    },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
