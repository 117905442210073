export const environment = {
  production: true,
  apiUrl: 'https://nb.api.preview-site.website',
  publicUrl: 'https://nb.preview-site.website',
  dashboardUrl: 'https://nb-dashboard.preview-site.website',
  googleMapsApiKey: 'AIzaSyDRTDyMguGSuakSd1jFCVHvV6ndQG96GFk',
  idealPostcodesApiKey: 'ak_hr19shxjfa0mtFMs56ZgD2xb5gqo2',
  clientSettings: {
    authority: 'https://nb.auth.preview-site.website',
    client_id: 'website',
    scope: 'openid profile email api file-download',
  },
  licenceAdminUrl: 'https://licences-nb.preview-site.website/',
  featureFlags: {
    showNBPlusBookPicker: true,
  },
};
