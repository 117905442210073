import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProcessingButtonModule } from '../../../modules/global/processing-button/processing-button.module';

@Component({
  selector: 'nbm-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    NgClass,
    NgIf,
    NgTemplateOutlet,
    ProcessingButtonModule,
  ],
})
export class CtaButtonComponent {
  @Input() processing: boolean = false;
  @Input() largeButton: boolean = false;
  @Input() routerLink: string[];
  @Input() additionalClasses: string;
  @Input() fragment: string;
  @Input() btnType?: string = 'dark';
  @Input() disabled: boolean = false;
  @Input() originalText: string;

  @Output()
  clicked = new EventEmitter<Boolean>();
}
