import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FullScreenLoadingComponent } from './components/full-screen-loading/full-screen-loading.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SmallLoadingComponent } from './components/small-loading/small-loading.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';

@NgModule({
  declarations: [
    LoadingComponent,
    WithLoadingPipe,
    SmallLoadingComponent,
    FullScreenLoadingComponent,
  ],
  imports: [CommonModule],
  exports: [
    LoadingComponent,
    WithLoadingPipe,
    SmallLoadingComponent,
    FullScreenLoadingComponent,
  ],
})
export class LoadingModule {}
