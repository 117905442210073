import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgbDateParserFormatter,
  NgbModalConfig,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PushOptimiseOnNavigateComponent } from './global/components/push-optimise-on-navigate/push-optimise-on-navigate.component';
import { ClearJsonLdOnNavDirective } from './global/directives/clear-json-ld-on-nav.directive';
import { ScrollToTopOnNavDirective } from './global/directives/scroll-to-top-on-nav.directive';
import { DataInterceptor } from './global/interceptors/data-interceptor';
import { AngularDateHttpInterceptor } from './global/interceptors/date-interceptor';
import { UkDateParserFormatter } from './global/uk-date-parser-formatter';
import { AuthModule } from './modules/global/auth/auth.module';
import { ImpersonationModule } from './modules/global/impersonation/impersonation.module';
import { LegacyRedirectionModule } from './modules/global/legacy-redirection/legacy-redirection.module';
import { ToastsModule } from './modules/global/toasts/toasts.module';
import { MapModule } from './modules/shared-components/map/map.module';

@NgModule({
  declarations: [
    AppComponent,
    ScrollToTopOnNavDirective,
    ClearJsonLdOnNavDirective,
    PushOptimiseOnNavigateComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    NgbNavModule,
    ToastsModule,
    ImpersonationModule,
    AuthModule,
    LegacyRedirectionModule,
    HttpClientModule,

    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerImmediately',
    // }),
    MapModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    {
      provide: NgbModalConfig,
      useValue: { backdrop: 'static' } as NgbModalConfig,
    },
    { provide: NgbDateParserFormatter, useClass: UkDateParserFormatter },
  ],
})
export class AppModule {}
