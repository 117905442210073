import { ContentTypeInfo } from 'src/app/shared/dtos/content-type-info';
import { ProductType } from 'src/app/shared/dtos/product-type';
import { ProductVariantSummary } from 'src/app/shared/dtos/product-variant-summary';

import { BookSummary, IBookSummary } from './book-summary';
import { ChapterInfo } from './chapter-info';
import { NBPlusBookDiscountStatus } from './nb-plus-book-discount-status';
import { ProductSummary } from 'src/app/shared/dtos/product-summary';

interface IBookDetails extends IBookSummary {
  description: string;

  productType: ProductType;

  chapters: ChapterInfo[];
}

export class BookDetails extends BookSummary implements IBookDetails {
  constructor(
    public id: string,
    public slug: string,
    public name: string,
    public contentTypes: ContentTypeInfo[],
    public image: string | null,
    public imageId: string | null,
    public previewPdf: string,
    public requiresShipping: boolean,
    public description: string,
    public releaseDate: Date,
    public released: boolean,

    public plusDiscountStatus: NBPlusBookDiscountStatus,
    public productType: ProductType,
    public date: Date,
    public variants: ProductVariantSummary[],
    public availableInNBPlus: boolean,
    public chapters: ChapterInfo[]
  ) {
    super(
      id,
      slug,
      name,
      contentTypes,
      image,
      imageId,
      previewPdf,
      requiresShipping,
      description,
      releaseDate,
      released,

      plusDiscountStatus,
      productType,
      date,
      variants,
      availableInNBPlus
    );
  }

  static FromJSON(data: any): BookDetails {
    if (!data) return null;
    return new BookDetails(
      data.id,
      data.slug,
      data.name,
      data.contentTypes,
      data.image,
      data.imageId,
      data.previewPdf,
      data.requiresShipping,
      data.description,
      data.releaseDate,
      data.released,

      data.plusDiscountStatus,
      data.productType,
      data.date,
      data.variants,
      data.availableInNBPlus,
      data.chapters
    );
  }
}
