import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { take } from 'rxjs/operators';
import { BasketService } from 'src/app/modules/routed-features/basket/services/basket.service';
import { CopyBasketOnLoginService } from 'src/app/modules/routed-features/basket/services/copy-basket-on-login.service';
import { environment } from 'src/environments/environment';

import { GoogleTagManagerService } from '../../gtm/services/google-tag-manager.service';
import { StorageService } from './storage-service';
import { UserService } from './user.service';

const redirectKey = 'login.redirectKey';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private userService: UserService,
    private copyBasketOnLoginService: CopyBasketOnLoginService,
    private basketService: BasketService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private oauthService: OAuthService
  ) {}
  private static setRedirectUrl(redirectUrl?: string) {
    if (StorageService.hasStorage) {
      StorageService.setItem(redirectKey, redirectUrl);
    }
  }
  private static getRedirectUrl() {
    if (StorageService.hasStorage) {
      const url = StorageService.getItem(redirectKey);
      StorageService.removeItem(redirectKey);
      return url;
    }
    return null;
  }
  async registerAutomaticRefresh() {
    this.oauthService.setupAutomaticSilentRefresh();
  }

  async login(redirectUrl?: string) {
    LoginService.setRedirectUrl(redirectUrl);
    return await this.oauthService.loadDiscoveryDocumentAndLogin().then((_) => {
      if (
        !this.oauthService.hasValidIdToken() ||
        !this.oauthService.hasValidAccessToken()
      ) {
        this.basketService
          .getUserBasket()
          .pipe(take(1))
          .subscribe((basket) => {
            this.oauthService.initLoginFlow('', {
              products: JSON.stringify(
                this.gtmService.getBasketProducts(basket)
              ),
            });
          });
        return false;
      } else {
        return true;
      }
    });
  }

  async logout() {
    await this.oauthService
      .loadDiscoveryDocument()
      .then((_) => this.oauthService.revokeTokenAndLogout());
  }

  async completeAuthentication(): Promise<boolean> {
    try {
      const result = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (!(result && this.oauthService.hasValidAccessToken())) {
        return false;
      }

      const user: any = await this.oauthService.loadUserProfile();
      this.userService.setUser(user?.info ?? null);

      await this.copyBasketOnLoginService.copyAnonymousItemsToUser(
        UserService.getLocalUserId()
      );

      const url = LoginService.getRedirectUrl();
      if (url) {
        this.router.navigateByUrl(url);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  getChangePasswordUrl(redirectUrl?: string) {
    return `${environment.clientSettings.authority}/account/myaccount?ReturnUrl=${redirectUrl}`;
  }
}
