import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[nbmProcessingButton]',
})
export class ProcessingButtonDirective implements OnChanges {
  @Input()
  processingText = 'Processing...';

  @HostBinding('disabled')
  @Input('nbmProcessingButton')
  processing = false;

  @Input()
  originalText: string;
  private _originalInnerText?: string;

  constructor(private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this._originalInnerText === undefined) {
      this._originalInnerText = this.element.nativeElement.innerText ?? '';
    }
    this.setText();
  }

  private setText() {
    if (this.processing) {
      this.element.nativeElement.innerText = this.processingText;
    } else {
      this.element.nativeElement.innerText =
        this.originalText ?? this._originalInnerText;
    }
  }
}
