import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ImpersonationDataService } from '../../services/impersonation-data.service';
import { ImpersonateUserComponent } from '../impersonate-user/impersonate-user.component';

@Component({
  selector: 'nbm-impersonation-bar',
  templateUrl: './impersonation-bar.component.html',
  styleUrls: ['./impersonation-bar.component.scss'],
})
export class ImpersonationBarComponent {
  shown = false;

  constructor(
    private modal: NgbModal,
    private impersonationDataService: ImpersonationDataService
  ) {}

  showImpersonateModal() {
    const modal = this.modal.open(ImpersonateUserComponent);
  }
  cancelImpersonation() {
    this.impersonationDataService.setData(null);
  }
  hideBar() {
    this.shown = false;
  }
  showBar() {
    this.shown = true;
  }

  get isImpersonating() {
    return this.impersonationDataService.getData() !== null;
  }

  get impersonatingEmail() {
    return this.impersonationDataService.getData()?.email;
  }
}
