import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProcessingButtonDirective } from './directives/processing-button.directive';

@NgModule({
  declarations: [ProcessingButtonDirective],
  imports: [CommonModule],
  exports: [ProcessingButtonDirective],
})
export class ProcessingButtonModule {}
