import { Injectable } from '@angular/core';
import { mergeMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/global/app-state';
import { RequestService } from 'src/app/global/services/request.service';
import { UserAddress } from 'src/app/modules/global/address/dtos/user-address';
import { UserProductVoucherSummary } from 'src/app/modules/shared-components/vouchers/dtos/product-voucher-summary';
import { ngbDateStructToString } from 'src/app/shared/helpers/date-helpers';
import { environment } from 'src/environments/environment';

import { ContactPreferences } from '../../basket/dtos/contact-preferences';
import { OrderSummary } from '../../order-complete/dtos/order-summary';
import { AddressBookUserAddress } from '../dtos/address-book-user-address';
import { OrdersSearchQuery } from '../dtos/orders-search-query';
import { UpdatedUserData } from '../dtos/updated-user-data';
import { UserData } from '../dtos/user-data';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  constructor(private requestService: RequestService) {
    AppState.impersonationStateChanged.subscribe(() => {
      this.getUserAddressesFromServer().subscribe();
      this.getUserInfoFromServer().subscribe();
    });
  }
  getUserAddresses() {
    if (AppState.userAddresses$.value) {
      return AppState.userAddresses$;
    }

    return this.getUserAddressesFromServer();
  }
  private getUserAddressesFromServer() {
    return this.requestService
      .userQuery<AddressBookUserAddress[]>('GetUserAddresses', {})
      .pipe(
        tap((userAddresses) => {
          AppState.userAddresses$.next(userAddresses);
        }),
        mergeMap((_) => AppState.userAddresses$)
      );
  }

  getUserOrders(query?: OrdersSearchQuery) {
    return this.requestService.userQuery<OrderSummary[]>('GetUserOrders', {
      dateFrom: ngbDateStructToString(query?.dateFrom),
      dateTo: ngbDateStructToString(query?.dateTo),
    });
  }

  getUserVouchers() {
    return this.requestService.userQuery<UserProductVoucherSummary[]>(
      'GetValidUserVouchers',
      {}
    );
  }

  getUserData() {
    if (AppState.userData$.value) {
      return AppState.userData$;
    }
    return this.getUserInfoFromServer();
  }
  getUserInfoFromServer() {
    return this.requestService.userQuery<UserData>('GetUserInfo', {}).pipe(
      tap((userInfo) => {
        AppState.userData$.next(userInfo);
      })
    );
  }

  setContactPreferences(preferences: ContactPreferences) {
    return this.requestService
      .userCommand<UserData>('SetContactPreferences', {
        preferences,
      })
      .pipe(
        mergeMap((info) => {
          AppState.userData$.next(info);
          return AppState.userData$;
        })
      );
  }
  updateUserData(userData: UpdatedUserData) {
    return this.requestService
      .userCommand<UserData>('UpdateUserData', {
        userData,
      })
      .pipe(
        mergeMap((info) => {
          AppState.userData$.next(info);
          return AppState.userData$;
        })
      );
  }
  makeAddressPrimary(address: AddressBookUserAddress) {
    return this.requestService
      .userCommand<AddressBookUserAddress[]>('MakeAddressPrimary', {
        id: address.id,
      })
      .pipe(
        tap((userAddresses) => {
          AppState.userAddresses$.next(userAddresses);
        })
      );
  }
  deleteAddress(address: AddressBookUserAddress) {
    return this.requestService
      .userCommand<AddressBookUserAddress[]>('DeleteAddress', {
        id: address.id,
      })
      .pipe(
        tap((userAddresses) => {
          AppState.userAddresses$.next(userAddresses);
        })
      );
  }
  addUserAddress(address: UserAddress) {
    return this.requestService
      .userCommand<AddressBookUserAddress[]>('AddUserAddress', {
        address,
      })
      .pipe(
        tap((userAddresses) => {
          AppState.userAddresses$.next(userAddresses);
        })
      );
  }
  getItemLink(itemType: string, itemId: string) {
    const dashUrl = environment.dashboardUrl;
    switch (itemType) {
      case 'PublicWebinar':
      case 'CCGWebinar':
        return dashUrl + '/account/webinars/' + itemId;
      case 'PublicCourse':
      case 'CCGCourse':
        return dashUrl + '/account/courses/';
      case 'NBPlus':
      case 'NBPlusUpsell':
      case 'OrganisationNBPlus':
        return dashUrl;
      case 'Book':
        return dashUrl + '/account/booklets/' + itemId;
      default:
        return undefined;
    }
  }
}
