export enum ProductType {
  PublicCourse = 'PublicCourse',
  PublicWebinar = 'PublicWebinar',
  Book = 'Book',
  CCGCourse = 'CCGCourse',
  CCGWebinar = 'CCGWebinar',
  Safeguarding = 'Safeguarding',
  BasicLifeSupport = 'BasicLifeSupport',
  CPDModules = 'CPDModules',
  NBPlus = 'NBPlus',
  NBPlusUpsell = 'NBPlusUpsell',
  OrganisationNBPlus = 'OrganisationNBPlus',
  ProductVoucher = 'ProductVoucher',
  Blog = 'Blog',
  Podcast = 'Podcast',
  PremiumPodcast = 'PremiumPodcast',
  Webinar = 'Webinar',
  KISS = 'KISS',
  Tag = 'Tag',
}

export const ProductTypes: ProductType[] = Object.values(ProductType);
