import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { map } from 'rxjs/operators';

import { AppState } from './global/app-state';
import { LoginService } from './modules/global/auth/services/login.service';
import { UserService } from './modules/global/auth/services/user.service';

// import { MessagePayload } from 'firebase/messaging';
// import { MessagingService } from './global/services/messaging.service';
@Component({
  selector: 'nbm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // message: MessagePayload = null;
  constructor(
    private loginService: LoginService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.loginService.registerAutomaticRefresh();
    }
  }

  get currentUserCanImpersonate$() {
    return AppState.user$.pipe(map((user) => UserService.canImpersonate(user)));
  }
}
