import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChangePasswordClickDirective } from './directives/change-password-click.directive';
import { LoginClickDirective } from './directives/login-click.directive';
import { LogoutClickDirective } from './directives/logout-click.directive';

@NgModule({
  declarations: [
    LoginClickDirective,
    LogoutClickDirective,
    ChangePasswordClickDirective,
  ],
  imports: [CommonModule],
  exports: [
    LoginClickDirective,
    LogoutClickDirective,
    ChangePasswordClickDirective,
  ],
})
export class LogInOutModule {}
