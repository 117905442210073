<a
  *ngIf="href; else internal"
  [href]="href"
  [class]="
    'text-dark btn btn-white border-dark border text-uppercase ' +
    additionalClasses
  "
  [ngClass]="{ 'btn-lg': largeButton }"
  [target]="target"
>
  <span
    *ngIf="icon"
    [class]="'icon-' + icon + ' icon-1x text-dark me-3'"
  ></span>
  {{ originalText }}
</a>
<ng-template #internal>
  <a
    *ngIf="routerLink; else actionButton"
    [routerLink]="routerLink"
    [class]="
      'text-dark btn btn-white border-dark border text-uppercase ' +
      additionalClasses
    "
    [ngClass]="{ 'btn-lg': largeButton }"
    [target]="target"
  >
    <span
      *ngIf="icon"
      [class]="'icon-' + icon + ' icon-1x text-dark me-3'"
    ></span>
    {{ originalText }}
  </a>
  <ng-template #actionButton>
    <button
      [class]="
        'text-dark btn btn-white border border-dark text-uppercase ' +
        additionalClasses
      "
      [ngClass]="{ 'btn-lg': largeButton }"
      (click)="clicked.emit()"
      [nbmProcessingButton]="processing"
      [originalText]="originalText"
    ></button>
  </ng-template>
</ng-template>
