import { OrganisationDetailSummary } from 'src/app/modules/shared-components/organisation/dtos/organisation-detail-summary';

import { BasketData } from './basket-data';

export class OrganisationBasketData extends BasketData {
  public organisation: OrganisationDetailSummary;
  public invoiceReference?: string;

  calculateTotals() {
    this.subtotal.price = this.items.reduce(
      (prev, curr) => prev + curr.subtotal.price * curr.quantity,
      0
    );

    this.subtotal.vat = this.items.reduce(
      (prev, curr) => prev + curr.subtotal.vat * curr.quantity,
      0
    );

    this.total.price = this.subtotal.price;
    this.total.vat = this.subtotal.vat;
  }
}
