import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RequestService } from 'src/app/global/services/request.service';

import { PageEnhancementInfo } from '../dtos/page-enhancement-info';
import { RoutedPageInfo } from '../dtos/routed-page-info';
import { SingleColumnPageInfo } from '../dtos/single-column-page-info';

@Injectable({
  providedIn: 'root',
})
export class CmsContentService {
  constructor(private requestService: RequestService) {}

  getRoutedPage(url: string) {
    return this.requestService
      .publicQuery<RoutedPageInfo>('GetRoutedPage', { url })
      .pipe(map(RoutedPageInfo.parse));
  }

  getPageEnhancementInfo(url: string) {
    return this.requestService
      .publicQuery<PageEnhancementInfo>('GetPageEnhancement', { url })
      .pipe(
        catchError((err) => of(null)),
        map(PageEnhancementInfo.parse)
      );
  }

  getSingleColumnPage(slug: string) {
    return this.requestService.publicQuery<SingleColumnPageInfo>(
      'GetSingleColumnPageInfo',
      {
        slug,
      }
    );
  }
}
