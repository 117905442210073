import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PageEnhancementInfo } from 'src/app/modules/routed-features/cms/dtos/page-enhancement-info';
import { CmsContentService } from 'src/app/modules/routed-features/cms/services/cms-content.service';

@Injectable({
  providedIn: 'root',
})
export class CmsDataResolver  {
  constructor(private cms: CmsContentService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PageEnhancementInfo> {
    return this.getPageEnhancementData(route, state.url);
  }

  private getPageEnhancementData(
    route: ActivatedRouteSnapshot,
    url: string
  ): Observable<PageEnhancementInfo> {
    const children: ActivatedRouteSnapshot[] = route?.children || [];

    if (children.length === 0) {
      return this.cms.getPageEnhancementInfo(url);
    }

    const child = children[0];
    const ignoreCMS = child.routeConfig.data?.ignoreCmsData === true;
    if (ignoreCMS) {
      return of(null);
    }

    return this.getPageEnhancementData(child, url);
  }
}
