import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingModule } from '../loading/loading.module';
import { LegacyBlogHandlerComponent } from './components/legacy-blog-handler/legacy-blog-handler.component';
import { LegacyBookTypeHandlerComponent } from './components/legacy-book-type-handler/legacy-book-type-handler.component';
import {
  LegacyCourseLocationHandlerComponent,
} from './components/legacy-course-location-handler/legacy-course-location-handler.component';
import {
  LegacyCourseTypeHandlerComponent,
} from './components/legacy-course-type-handler/legacy-course-type-handler.component';
import { LegacyPackageHandlerComponent } from './components/legacy-package-handler/legacy-package-handler.component';
import { LegacySimpleHandlerComponent } from './components/legacy-simple-handler/legacy-simple-handler.component';
import {
  LegacyWebinarTypeHandlerComponent,
} from './components/legacy-webinar-type-handler/legacy-webinar-type-handler.component';
import { LegacyCourseSubjectHandlerComponent } from './components/legacy-course-subject-handler/legacy-course-subject-handler.component';

@NgModule({
  declarations: [
    LegacyCourseTypeHandlerComponent,
    LegacyCourseLocationHandlerComponent,
    LegacyPackageHandlerComponent,
    LegacyWebinarTypeHandlerComponent,
    LegacyBookTypeHandlerComponent,
    LegacyBlogHandlerComponent,
    LegacySimpleHandlerComponent,
    LegacyCourseSubjectHandlerComponent,
  ],
  imports: [CommonModule, LoadingModule],
  exports: [
    LegacyCourseTypeHandlerComponent,
    LegacyCourseLocationHandlerComponent,
    LegacyPackageHandlerComponent,
    LegacyWebinarTypeHandlerComponent,
    LegacyBookTypeHandlerComponent,
    LegacyBlogHandlerComponent,
    LegacySimpleHandlerComponent,
    LegacyCourseSubjectHandlerComponent,
  ],
})
export class LegacyRedirectionModule {}
