import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductType } from 'src/app/shared/dtos/product-type';

@Injectable({
  providedIn: 'root',
})
export class PageRouterService {
  constructor(private router: Router) {}

  routeToPage(type: string, slug: string) {
    switch (type) {
      case ProductType.BasicLifeSupport:
        this.router.navigate(['/appraisal-essentials', slug]);
        break;
      case ProductType.Book:
        this.router.navigate(['/books', slug]);
        break;
      case ProductType.CCGCourse:
        this.router.navigate(['/face-to-face-courses', slug]);
        break;
      case ProductType.CCGWebinar:
        this.router.navigate(['/webinars', slug]);
        break;
      case ProductType.CPDModules:
        this.router.navigate(['/appraisal-essentials', slug]);
        break;
      case ProductType.NBPlus:
        this.router.navigate(['/nb-plus']);
        break;
      case ProductType.OrganisationNBPlus:
        this.router.navigate(['/nb-plus/organisation', slug]);
        break;
      case ProductType.PublicCourse:
        this.router.navigate(['/face-to-face-courses', slug]);
        break;
      case ProductType.PublicWebinar:
        this.router.navigate(['/webinars', slug]);
        break;
      case ProductType.Safeguarding:
        this.router.navigate(['/appraisal-essentials', slug]);
        break;
      case 'BlogEntry':
        this.router.navigate(['/blog', slug]);
        break;
      case 'Podcast':
        this.router.navigate(['/podcast', slug]);
        break;
      case 'KISS':
        this.router.navigate(['/kiss', slug]);
        break;
      case ProductType.Tag:
        this.router.navigate(['/content-explorer', slug]);
        break;
    }
  }
}
