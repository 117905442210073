import { UserAddress } from 'src/app/modules/global/address/dtos/user-address';

import { ProductType } from '../../../../shared/dtos/product-type';
import { BasketItem } from './basket-item';
import { BasketRecurring } from './basket-recurring';
import { BasketState } from './basket-state';
import { CartPaymentMethod } from './cart-payment-method';
import { DiscountInfo } from './discount-info';
import { NBPlusType } from './nb-plus-type';
import { Price } from './price';
import { ShippingOption } from './shipping-option';

export class BasketData {
  public items: BasketItem[];

  public state: BasketState;

  public subtotalPreDiscount: Price;
  public subtotalDiscount: Price;
  public subtotal: Price;
  public get hasSubtotalDiscount() {
    return this.subtotalDiscount.price > 0;
  }

  public shippingTotalPreDiscount: Price;
  public shippingTotalDiscount: Price;
  public shippingTotal: Price;
  public get hasShippingTotalDiscount() {
    return this.shippingTotalDiscount.price > 0;
  }

  public total: Price;
  public storeCredit: number;
  public balanceToPay: number;

  public billingAddress: UserAddress;
  public requiresShipping: boolean;
  public shippingAddressSameAsBillingAddress: boolean;
  public shippingAddress: UserAddress;
  public shippingOption: ShippingOption;
  public tcAgreed: boolean;
  public discounts: DiscountInfo[];
  public iframeUrl: string;

  public recurring: BasketRecurring;

  public userIsAlreadyNBPlusSubscriber: boolean;

  public containsAnyNBPlus: boolean;
  public nbPlusType: NBPlusType;
  public containsPremiumCourse: boolean;
  public isValidForFixedTermNBPlus: boolean;
  public hasNBPlusUpgradeOffer: boolean;
  public isEligibleForCPDModulesDiscount: boolean;

  public paymentOptions: CartPaymentMethod[];
  public paymentMethod: CartPaymentMethod;
  public previouslyPurchasedProductIds: string[];

  get itemCount() {
    return this.items.length;
  }
  get isEmpty() {
    return this.itemCount === 0;
  }
  contains(productId: string): boolean {
    return this.items.filter((x) => x.productId === productId)[0] !== undefined;
  }

  get termsAndConditionsRequired() {
    return this.state === BasketState.TermsAndConditionsRequired;
  }
  get requiresPaymentMethodChoice() {
    return this.state === BasketState.ChoosePaymentMethod;
  }
  get canComplete() {
    return this.state === BasketState.CanComplete;
  }
  get isReadyToPay() {
    return this.state === BasketState.ReadyToPay;
  }

  get isPaying() {
    return this.state === BasketState.Paying;
  }
  get isInvalid() {
    return !this.isValid;
  }
  get isValid() {
    return this.state !== BasketState.Invalid;
  }
  get isInPaymentStage() {
    return (
      this.state === BasketState.TermsAndConditionsRequired ||
      this.state === BasketState.ReadyToPay ||
      this.state === BasketState.Paying ||
      this.state === BasketState.CanComplete ||
      this.state === BasketState.ChoosePaymentMethod
    );
  }
  get containsFreeBook(): boolean {
    return this.items.some(
      (item) =>
        item.productType === ProductType.Book && item.subtotal.price === 0
    );
  }

  get showNBPlusInfo() {
    return (
      !this.userIsAlreadyNBPlusSubscriber &&
      !this.items.some(
        (x) =>
          x.productType === ProductType.NBPlus ||
          x.productType === ProductType.NBPlusUpsell ||
          x.productType === ProductType.OrganisationNBPlus
      )
    );
  }
}
