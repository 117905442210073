import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbCollapseModule,
  NgbModalModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';

import { LoadingModule } from '../modules/global/loading/loading.module';
import { LogInOutModule } from '../modules/global/log-in-out/log-in-out.module';
import { RemoteContentModule } from '../modules/global/remote-content/remote-content.module';
import { AddToMailingListModalComponent } from './components/add-to-mailing-list-modal/add-to-mailing-list-modal.component';
import { BoxContentComponent } from './components/box-content/box-content.component';
import { CardContentComponent } from './components/card-content/card-content.component';
import { CmsHtmlAreaComponent } from './components/cms-html-area/cms-html-area.component';
import { CmsImageRenderComponent } from './components/cms-image-render/cms-image-render.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderNavigationModule } from './components/header-navigation/header-navigation.module';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageRenderComponent } from './components/image-render/image-render.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { MediaContentComponent } from './components/media-content/media-content.component';
import { NewsletterSignupComponent } from './components/newsletter-signup/newsletter-signup.component';
import { NoPharmaNoticeComponent } from './components/no-pharma-notice/no-pharma-notice.component';
import { OverlayContentComponent } from './components/overlay-content/overlay-content.component';
import { RoutingLoaderComponent } from './components/routing-loader/routing-loader.component';
import { SiteModalComponent } from './components/site-modal/site-modal.component';
import { SitePageComponent } from './components/site-page/site-page.component';
import { PluralPipe } from './pipes/plural.pipe';
import { QuotationComponent } from './components/quotation/quotation.component';
import { TitleWithIconComponent } from './components/title-with-icon/title-with-icon.component';
import { CtaButtonComponent } from './components/cta-button/cta-button.component';
import { PlainButtonComponent } from './components/plain-button/plain-button.component';
import { ProcessingButtonModule } from '../modules/global/processing-button/processing-button.module';
import { NbOnCallModule } from '../modules/shared-components/nb-on-call/nb-on-call.module';
import { MegaMenuModule } from './components/mega-menu/mega-menu.module';

@NgModule({
  declarations: [
    FooterComponent,
    SitePageComponent,
    HeaderComponent,
    JumbotronComponent,
    SiteModalComponent,
    OverlayContentComponent,
    BoxContentComponent,
    MediaContentComponent,
    CardContentComponent,
    RoutingLoaderComponent,
    NoPharmaNoticeComponent,
    PluralPipe,
    CmsHtmlAreaComponent,
    ImageRenderComponent,
    AddToMailingListModalComponent,
    NewsletterSignupComponent,
    CmsImageRenderComponent,
    QuotationComponent,
    TitleWithIconComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModalModule,
    NgbCollapseModule,
    LoadingModule,
    LogInOutModule,
    RemoteContentModule,
    NgbTypeaheadModule,
    FormsModule,
    ReactiveFormsModule,
    ProcessingButtonModule,
    NbOnCallModule,
    MegaMenuModule,
    CtaButtonComponent,
    PlainButtonComponent,
    HeaderNavigationModule,
    HeaderSearchComponent,
  ],
  exports: [
    SitePageComponent,
    CmsHtmlAreaComponent,
    JumbotronComponent,
    SiteModalComponent,
    OverlayContentComponent,
    BoxContentComponent,
    MediaContentComponent,
    CardContentComponent,
    RoutingLoaderComponent,
    PluralPipe,
    ImageRenderComponent,
    CmsImageRenderComponent,
    QuotationComponent,
    TitleWithIconComponent,
  ],
})
export class CoreUIModule {}
