import { isPlatformBrowser } from '@angular/common';
import { Directive, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
  selector: '[nbmScrollToTopOnNav]',
})
export class ScrollToTopOnNavDirective implements OnInit {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platform: any
  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }
  }
}
