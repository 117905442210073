import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DayjsDatePipe } from './pipes/dayjs-date.pipe';

@NgModule({
  declarations: [DayjsDatePipe],
  imports: [CommonModule],
  exports: [DayjsDatePipe],
})
export class DateFormatModule {}
