import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestService } from 'src/app/global/services/request.service';
import { TagsService } from 'src/app/modules/routed-features/tags/tags.service';
import { ProductType } from 'src/app/shared/dtos/product-type';

import { SearchData } from '../../dtos/search-data';

const expander = /([a-z](?=[A-Z]))/g;

@Injectable({
  providedIn: 'root',
})
export class HeaderSearchService {
  private _allData$: BehaviorSubject<SearchData[]> = new BehaviorSubject<
    SearchData[]
  >([]);

  constructor(
    private requestService: RequestService,
    private tagsService: TagsService
  ) {
    this.loadInitialData();
  }

  get allData$(): Observable<SearchData[]> {
    return this._allData$;
  }
  getWords(term: string) {
    if (term === '' || term === undefined || term === null) return [];
    const loweredTerm = term?.toLowerCase();
    return loweredTerm.split(' ').filter((x) => x !== '');
  }
  search(term: string): Observable<SearchData[]> {
    const words = this.getWords(term);
    if (!words.length) return of([]);
    return this.allData$.pipe(
      map((products) => {
        return products
          .filter((v) =>
            words.every((word) => v.loweredName.indexOf(word) > -1)
          )
          .slice(0, 10);
      })
    );
  }

  loadInitialData() {
    const tags$ = this.tagsService.getAllTags();
    const searchData$ = this.requestService.publicQuery<SearchData[]>(
      'GetSearchData',
      {}
    );

    forkJoin([tags$, searchData$]).subscribe(([tags, searchData]) => {
      const tagSearchItems: SearchData[] = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.text,
          loweredName: tag.text.toLowerCase(),
          type: 'Tag',
          formattedType: 'Tag',
          slug: tag.slug,
          priority: 0,
          daysAway: 0,
        };
      });

      const result = [...searchData, ...tagSearchItems];

      result.forEach((data) => {
        data.loweredName = data.name.toLowerCase();
        data.formattedType = this._getFormattedType(data.type);
        data.priority = this._getPriority(data.type);
      });
      // sort by priority, then by days old
      result
        .sort((a, b) => {
          if (a.priority === b.priority) {
            return a.daysAway - b.daysAway;
          }
          return a.priority - b.priority;
        })
        .map((data) => ({
          ...data,
          priority: data.priority || 0,
          daysAway: data.daysAway || 0,
        }));
      this._allData$.next(result);
    });
  }
  private _getFormattedType(type: string): string {
    switch (type) {
      case ProductType.NBPlus:
        return '';
      case ProductType.CCGWebinar:
      case ProductType.PublicWebinar:
        return 'Webinar';
      case ProductType.CCGCourse:
      case ProductType.PublicCourse:
        return 'Course';
      case ProductType.BasicLifeSupport:
      case ProductType.CPDModules:
      case ProductType.Safeguarding:
        return 'Appraisal Essential';
      case ProductType.OrganisationNBPlus:
        return 'NB Plus Package';
      case 'BlogEntry':
        return 'Blog';
      default:
        return type.replace(expander, '$1 ').trim();
    }
  }
  private _getPriority(type: string): number {
    switch (type) {
      case ProductType.NBPlus:
        return 0;
      case ProductType.Tag:
        return 1;
      case ProductType.PublicWebinar:
        return 2;
      case ProductType.PublicCourse:
        return 3;
      case ProductType.Book:
        return 4;
      case ProductType.BasicLifeSupport:
      case ProductType.CPDModules:
      case ProductType.Safeguarding:
        return 5;
      case ProductType.CCGCourse:
      case ProductType.CCGWebinar:
      case ProductType.OrganisationNBPlus:
        return 6;
      case 'BlogEntry':
      case 'Podcast':
      case 'KISS':
        return 7;
      default:
        return 9999999;
    }
  }
}
