import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentListService {
  private list: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((x) => x instanceof NavigationEnd),
        map((_) => this.activatedRoute.snapshot)
      )
      .subscribe((route) => {
        this.list = this.getCurrentList(route);
      });
  }
  get currentList(): string {
    return this.list;
  }
  private getCurrentList(route: ActivatedRouteSnapshot) {
    let type = null;
    let list = '';
    let thisRoute = route;
    while (!type) {
      if (thisRoute.firstChild != null) {
        thisRoute = thisRoute.firstChild;
        continue;
      }

      if (thisRoute.component) {
        list = thisRoute.data.list;
        type = thisRoute.component;
      }
      break;
    }
    if (list) {
      return list;
    }
    if (!type) {
      return 'Not defined';
    }
    if (typeof type === 'string') {
      return type;
    }
    return type.name;
  }
}
