import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CtaButtonComponent } from 'src/app/core-ui/components/cta-button/cta-button.component';
import { BooksService } from 'src/app/modules/routed-features/books/services/books.service';

@Component({
  selector: 'nbm-nb-plus-book-offer-short-promo',
  templateUrl: './nb-plus-book-offer-short-promo.component.html',
  styleUrls: ['./nb-plus-book-offer-short-promo.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule, CtaButtonComponent],
})
export class NbPlusBookOfferShortPromoComponent {
  @Input()
  additionalClasses: string;
  constructor(private bookService: BooksService) {}

  get nbPlusBookPrice(): number {
    return this.bookService.getNBPlusBookPrice();
  }
}
