import { CMSPropertyInfo } from './cms-property-info';

export class RoutedPageInfo {
  constructor(
    public type: string,
    public url: string,
    public properties: CMSPropertyInfo[]
  ) {}

  static parse(info: RoutedPageInfo): RoutedPageInfo {
    if (!info) return null;
    return new RoutedPageInfo(
      info.type,
      info.url,
      info.properties.map(CMSPropertyInfo.parse)
    );
  }

  getValue(key: string) {
    return this.properties.find((x) => x.key === key)?.value || '';
  }

  get title() {
    return this.getValue('Title');
  }

  get metaDescription() {
    return this.getValue('MetaDescription');
  }

  get body() {
    return this.getValue('Body');
  }
}
