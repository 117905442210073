import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbOnCallComponent } from './nb-on-call.component';

@NgModule({
  declarations: [NbOnCallComponent],
  imports: [CommonModule],
  exports: [NbOnCallComponent],
})
export class NbOnCallModule {}
