import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-blog-handler',
  templateUrl: './legacy-blog-handler.component.html',
  styleUrls: ['./legacy-blog-handler.component.scss'],
})
export class LegacyBlogHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const id = (params.id as string) || '';
      if (!id) {
        return this.legacyRedirectionService.redirectTo('/blog');
      }

      this.legacyRedirectionService.getBlog(id).subscribe((result) => {
        if (result) {
          return this.legacyRedirectionService.redirectTo(
            `/blog/${result.slug}`
          );
        }
        return this.legacyRedirectionService.redirectTo('/blog');
      });
    });
  }
}
