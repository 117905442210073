import { isPlatformBrowser } from '@angular/common';
import { Directive, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { JsonLdService } from '../services/json-ld.service';

@Directive({
  selector: '[nbmClearJsonLdOnNav]',
})
export class ClearJsonLdOnNavDirective implements OnInit {
  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) private platform: any,
    private jsonLDService: JsonLdService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationStart)) {
          return;
        }
        this.jsonLDService.removeExisting();
      });
    }
  }
}
