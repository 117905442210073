import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nbm-site-modal',
  templateUrl: './site-modal.component.html',
  styleUrls: ['./site-modal.component.scss'],
})
export class SiteModalComponent {
  @Input()
  heading: string;
  @Input()
  hideFooter = false;

  @Input()
  loading = false;

  @Input()
  acting = false;

  constructor(public activeModal: NgbActiveModal) {}
}
