import { Route } from '@angular/router';

import { caseInsensitiveMatcher } from '../../../global/case-insensitive-matcher';
import { LegacyBlogHandlerComponent } from './components/legacy-blog-handler/legacy-blog-handler.component';
import { LegacyBookTypeHandlerComponent } from './components/legacy-book-type-handler/legacy-book-type-handler.component';
import { LegacyCourseLocationHandlerComponent } from './components/legacy-course-location-handler/legacy-course-location-handler.component';
import { LegacyCourseSubjectHandlerComponent } from './components/legacy-course-subject-handler/legacy-course-subject-handler.component';
import { LegacyCourseTypeHandlerComponent } from './components/legacy-course-type-handler/legacy-course-type-handler.component';
import { LegacyPackageHandlerComponent } from './components/legacy-package-handler/legacy-package-handler.component';
import { LegacySimpleHandlerComponent } from './components/legacy-simple-handler/legacy-simple-handler.component';
import { LegacyWebinarTypeHandlerComponent } from './components/legacy-webinar-type-handler/legacy-webinar-type-handler.component';
import { simpleRedirects } from './simple-redirects';

export const legacyRedirectionRoutes: Route[] = [
  ...simpleRedirects.map(
    (pair) =>
      ({
        // path: pair.old,
        matcher: caseInsensitiveMatcher(pair.previous),
        component: LegacySimpleHandlerComponent,
        data: {
          redirectTo: `/${pair.current}`,
        },
      } as Route)
  ),
  // ...simpleRedirects.map(
  //   (pair) =>
  //     ({
  //       path: pair.old.toLowerCase(),
  //       component: LegacySimpleHandlerComponent,
  //       data: {
  //         redirectTo: `/${pair.new}`,
  //       },
  //     } as Route)
  // ),
  {
    // path: 'NBListEvents',
    matcher: caseInsensitiveMatcher('NBListEvents'),
    component: LegacyCourseTypeHandlerComponent,
  },
  {
    // path: 'NBListGeoEvents',
    matcher: caseInsensitiveMatcher('NBListGeoEvents'),
    component: LegacyCourseLocationHandlerComponent,
  },
  {
    // path: 'NBPackageSelection',
    matcher: caseInsensitiveMatcher('NBPackageSelection'),
    component: LegacyPackageHandlerComponent,
    data: {
      type: 'Course',
    },
  },
  {
    // path: 'NBWebinarSelection',
    matcher: caseInsensitiveMatcher('NBWebinarSelection'),
    component: LegacyPackageHandlerComponent,
    data: {
      type: 'Webinar',
    },
  },
  {
    // path: 'NBListWebinars',
    matcher: caseInsensitiveMatcher('NBListWebinars'),
    component: LegacyWebinarTypeHandlerComponent,
  },
  {
    // path: 'NBListBooklets',
    matcher: caseInsensitiveMatcher('NBListBooklets'),
    component: LegacyBookTypeHandlerComponent,
  },
  {
    // path: 'NBBookletSelection',
    matcher: caseInsensitiveMatcher('NBBookletSelection'),
    component: LegacyPackageHandlerComponent,
    data: {
      type: 'Book',
    },
  },
  {
    // path: 'NBModulesSelection',
    matcher: caseInsensitiveMatcher('NBModulesSelection'),
    component: LegacyPackageHandlerComponent,
    data: {
      type: 'AppraisalEssential',
    },
  },
  {
    // path: 'nb_blog',
    matcher: caseInsensitiveMatcher('nb_blog'),
    component: LegacyBlogHandlerComponent,
  },
  {
    // path: 'nb_blog',
    matcher: caseInsensitiveMatcher('courses/subject/:subject'),
    component: LegacyCourseSubjectHandlerComponent,
  },
];
