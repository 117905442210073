<nbm-site-modal heading="Impersonate User">
  <ng-container modal-body>
    <ng-container *ngIf="isCheckEmail">
      <form [formGroup]="impersonateForm" (keydown)="onKeyDown($event)">
        <div class="mb-3">
          <label for="email">Email</label>
          <input type="email" class="form-control" formControlName="email" />
        </div>
      </form>
      <div class="alert alert-danger" *ngIf="showImpersonateValidationError">
        <h5>Error impersonating user</h5>
        Check the email address you have entered is valid
      </div>
      <div class="alert alert-warning" *ngIf="showCreatePrompt">
        <h5>User not found</h5>
        <button
          type="button"
          class="btn btn-primary"
          (click)="switchToCreateUser()"
        >
          Create user?
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="isCreateUser">
      <form [formGroup]="createUserForm">
        <div class="mb-3">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" formControlName="firstName" />
        </div>
        <div class="mb-3">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" formControlName="lastName" />
        </div>
        <div class="mb-3">
          <label for="email">Email</label>
          <input type="email" class="form-control" formControlName="email" />
        </div>
      </form>
      <div class="alert alert-danger" *ngIf="showCreateValidationError">
        <h5>Error creating user</h5>
        Check the values you have entered are valid
      </div>
      <div class="alert alert-danger" *ngIf="showCreateError">
        <h5>Error creating user</h5>
        {{ createError }}
      </div>
    </ng-container>
  </ng-container>
  <button
    class="btn btn-primary"
    modal-button
    (click)="tryImpersonate()"
    *ngIf="isCheckEmail"
  >
    Impersonate
  </button>
  <button
    class="btn btn-primary"
    modal-button
    (click)="tryCreateUser()"
    *ngIf="isCreateUser"
  >
    Create User
  </button>
</nbm-site-modal>
