import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

@Component({
  selector: 'nbm-legacy-simple-handler',
  templateUrl: './legacy-simple-handler.component.html',
  styleUrls: ['./legacy-simple-handler.component.scss'],
})
export class LegacySimpleHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const redirectTo = data.redirectTo;
      if (redirectTo) {
        return this.legacyRedirectionService.redirectTo(redirectTo);
      }
      return this.legacyRedirectionService.redirectTo('/');
    });
  }
}
