import { CMSPropertyInfo } from './cms-property-info';

export class PageEnhancementInfo {
  constructor(public url: string, public properties: CMSPropertyInfo[]) {}

  static parse(info: PageEnhancementInfo): PageEnhancementInfo {
    if (!info) return null;
    return new PageEnhancementInfo(
      info.url,
      info.properties.map(CMSPropertyInfo.parse)
    );
  }

  getValue(key: string) {
    return this.properties.find((x) => x.key === key)?.value || '';
  }

  get title() {
    return this.getValue('Title');
  }

  get metaTitle() {
    return this.getValue('MetaTitle');
  }

  get metaDescription() {
    return this.getValue('MetaDescription');
  }

  get body() {
    return this.getValue('Body');
  }
}
