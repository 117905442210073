import { Component, Input } from '@angular/core';

@Component({
  selector: 'nbm-full-screen-loading',
  templateUrl: './full-screen-loading.component.html',
  styleUrls: ['./full-screen-loading.component.scss'],
})
export class FullScreenLoadingComponent {
  @Input()
  ellipsisClass = 'border-white';

  @Input()
  textClass = '';

  @Input()
  text;

  @Input()
  minHeight = '200px';

  get displayText() {
    return this.text || 'Loading...';
  }
}
