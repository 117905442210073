import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../../../../global/app-state';
import { StorageService } from './storage-service';

const userIdKey = 'user.userIdKey';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private oauthService: OAuthService) {}
  get currentUser$(): Observable<UserInfo> {
    return AppState.user$;
  }
  get currentUserValue(): UserInfo {
    return AppState.user$.value;
  }

  static isAuthenticated(user: UserInfo): boolean {
    if (!user) {
      return false;
    }
    const expiresAt = StorageService.getItem('expires_at');
    if (!expiresAt) {
      return false;
    }
    const expiresAtValue = parseInt(expiresAt, 10);
    if (isNaN(expiresAtValue) || expiresAtValue <= 0) {
      return false;
    }
    return dayjs().isBefore(dayjs(expiresAtValue));
  }

  static getUserRoles(user: UserInfo): string[] {
    return user != null && this.isAuthenticated && user.role
      ? Array.isArray(user.role)
        ? user.role
        : [user.role]
      : [];
  }

  static userIsInRole(user: UserInfo, role: string) {
    const roles = this.getUserRoles(user);
    return roles.some((x) => x === role);
  }
  static canImpersonate(user: UserInfo) {
    return UserService.userIsInRole(user, 'Impersonator');
  }
  static isAdmin(user: UserInfo) {
    return UserService.userIsInRole(user, 'WebsiteAdmin');
  }
  static isNBPlusSubscriber(user: UserInfo) {
    return user !== null && user.isNBPlusSubscriber === 'true';
  }
  static getAuthorizationHeaderValue(user: UserInfo): string {
    const isAuthenticated = this.isAuthenticated(user);
    return !isAuthenticated ? null : `${user.token_type} ${user.access_token}`;
  }
  static getUserName(user: UserInfo): string {
    return user?.name || '';
  }
  static getUserId(user: UserInfo) {
    if (user) {
      return user?.sub;
    }
    return this.getLocalUserId();
  }

  static getLocalUserId() {
    if (StorageService.hasStorage) {
      const value = StorageService.getItem(userIdKey);
      if (value) {
        return value;
      }
      const newValue = uuidv4();
      StorageService.setItem(userIdKey, newValue);
      return newValue;
    }
    return uuidv4();
  }
  async reloadUserToken() {
    try {
      if (this.oauthService.hasValidAccessToken()) {
        await this.oauthService.silentRefresh();
      }
      return true;
    } catch {
      return false;
    }
  }
  async reloadUserProfile() {
    let hasValidToken = false;
    try {
      await this.oauthService.loadDiscoveryDocument();
      hasValidToken = this.oauthService.hasValidAccessToken();
    } catch {}

    if (!hasValidToken) {
      AppState.user$.next(null);
      return false;
    }

    try {
      const user: any = await this.oauthService.loadUserProfile();
      const info = user?.info ?? null;
      AppState.user$.next(info);
      return info !== null;
    } catch {
      AppState.user$.next(null);
      this.oauthService.logOut(true);
      location.reload();
      return false;
    }
  }
  public async setUserFromAuthServer() {
    await this.oauthService.loadDiscoveryDocument();
    const user: any = await this.oauthService.loadUserProfile();
    AppState.user$.next(user?.info ?? null);
  }
  setUser(user: UserInfo) {
    AppState.user$.next(user);
  }
  getAccessToken() {
    return this.oauthService.getAccessToken();
  }
}
