import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreUIModule } from '../../../core-ui/core-ui.module';
import { ImpersonateUserComponent } from './components/impersonate-user/impersonate-user.component';
import { ImpersonationBarComponent } from './components/impersonation-bar/impersonation-bar.component';

@NgModule({
  declarations: [ImpersonationBarComponent, ImpersonateUserComponent],
  imports: [CommonModule, NgbModalModule, CoreUIModule, ReactiveFormsModule],
  exports: [ImpersonationBarComponent],
})
export class ImpersonationModule {}
