import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  APP_INITIALIZER,
  NgModule,
  PLATFORM_ID,
  Provider,
} from '@angular/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AppState } from 'src/app/global/app-state';
import { environment } from 'src/environments/environment';

import { authConfig } from './auth-config';
import { StorageService } from './services/storage-service';
import { UserService } from './services/user.service';

const providers: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeOAuthService,
    multi: true,
    deps: [OAuthService, UserService, PLATFORM_ID],
  },
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: initializeUser,
  //   multi: true,
  //   deps: [UserService],
  // },
];
if (StorageService.hasStorage) {
  providers.push({ provide: OAuthStorage, useFactory: storageFactory });
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [],
  // This is initialised here so that it's guaranteed to happen on app init
  providers: providers,
})
export class AuthModule {}

function initializeOAuthService(
  oauthService: OAuthService,
  userService: UserService,
  platform: object
) {
  return async () => {
    if (isPlatformBrowser(platform)) {
      if (location.origin !== environment.publicUrl) {
        const pathAndQuery = location.pathname + location.search;
        location.href = environment.publicUrl + pathAndQuery;
        return;
      }
      oauthService.configure(authConfig);
      if (!StorageService.isSSR) {
        await userService.reloadUserProfile();
        await userService.reloadUserToken();
      } else {
        AppState.user$.next(null);
      }
    }
  };
}
// function initializeUser(userService: UserService) {
//   return async () => {
//     if (!StorageService.isSSR) {
//       await userService.reloadUserProfile();
//       // await userService.currentUser$.pipe(take(1)).toPromise();
//     }
//   };
// }

// We need a factory, since localStorage is not available during AOT build time.
function storageFactory(): OAuthStorage {
  return StorageService.oauthStorage;
}
