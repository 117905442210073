import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProcessingButtonModule } from '../../../modules/global/processing-button/processing-button.module';

@Component({
  selector: 'nbm-plain-button',
  templateUrl: './plain-button.component.html',
  styleUrls: ['./plain-button.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    RouterModule,
    NgTemplateOutlet,
    ProcessingButtonModule,
  ],
})
export class PlainButtonComponent {
  @Input() processing: boolean = false;
  @Input() largeButton: boolean = false;
  @Input() routerLink: string[];
  @Input() href: string;
  @Input() additionalClasses: string;
  @Input() target: string = '_self';
  @Input() originalText: string;
  @Input() icon: string;
  @Output()
  clicked = new EventEmitter<Boolean>();
}
