import { ProductType } from '../../../../shared/dtos/product-type';
import { Price } from './price';

export class BasketItem {
  productId: string;
  productType: ProductType;
  slug: string;
  name: string;
  image: string;
  subtotalPreDiscount: Price;
  discountAmount: Price;
  subtotal: Price;
  unitPrice: Price;
  productVariantId: string;
  quantity: number;

  validationErrors: string[];

  get hasDiscount() {
    return this.discountAmount.price > 0;
  }
}
