import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { RemoteContentModule } from '../../global/remote-content/remote-content.module';
import { DynamicMapComponent } from './dynamic-map/dynamic-map.component';
import { MapDisplayComponent } from './map-display/map-display.component';

@NgModule({
  declarations: [MapDisplayComponent, DynamicMapComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    RemoteContentModule,
    RouterModule,
  ],
  exports: [MapDisplayComponent, DynamicMapComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadGoogleMapsAPI,
      multi: true,
      deps: [HttpClient, PLATFORM_ID],
    },
  ],
})
export class MapModule {}

function loadGoogleMapsAPI(http: HttpClient, platform: object) {
  return () => {
    if (isPlatformBrowser(platform)) {
      return http
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`,
          'callback'
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        )
        .toPromise();
    } else {
      return Promise.resolve(true);
    }
  };
}
