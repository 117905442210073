import { ProductType } from 'src/app/shared/dtos/product-type';

import { ContentTypeInfo } from './content-type-info';
import { ProductVariantSummary } from './product-variant-summary';

export interface IProductSummary {
  id: string;
  slug: string;
  name: string;
  productType: ProductType;
  description: string;
  image: string | null;
  imageId: string | null;
  date: Date;
  requiresShipping: boolean;
  variants: ProductVariantSummary[];
  availableInNBPlus: boolean;
  contentTypes: ContentTypeInfo[];
}

export class ProductSummary implements IProductSummary {
  constructor(
    public id: string,
    public slug: string,
    public name: string,
    public productType: ProductType,
    public description: string,
    public image: string | null,
    public imageId: string | null,
    public date: Date,
    public requiresShipping: boolean,
    public variants: ProductVariantSummary[],
    public availableInNBPlus: boolean,
    public contentTypes: ContentTypeInfo[]
  ) {}
}
