import { UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, Subject } from 'rxjs';

import { BasketData } from '../modules/routed-features/basket/dtos/basket-data';
import { CountryData } from '../modules/routed-features/basket/dtos/country-data';
import { GroupBookingBasket } from '../modules/routed-features/group-booking/dtos/group-booking-basket';
import { AddressBookUserAddress } from '../modules/routed-features/my-account/dtos/address-book-user-address';
import { UserData } from '../modules/routed-features/my-account/dtos/user-data';
import { UserSubscriptionStatus } from '../modules/shared-components/subscription-management/dtos/user-subscription-status';

class AppStateData {
  constructor() {
    this.initialize();
  }
  get isUserInitialized() {
    return this.user$.value !== this.initialUserInfo;
  }
  private initialUserInfo = {} as UserInfo;
  public user$: BehaviorSubject<UserInfo>;
  public basket$: BehaviorSubject<BasketData>;
  public countries$: BehaviorSubject<CountryData[]>;
  public userAddresses$: BehaviorSubject<AddressBookUserAddress[]>;
  public subscriptionStatus$: BehaviorSubject<UserSubscriptionStatus>;
  public loggedIn$: BehaviorSubject<boolean>;
  public groupBaskets: { [id: string]: BehaviorSubject<GroupBookingBasket> };
  public userData$: BehaviorSubject<UserData>;
  public impersonationStateChanged = new Subject<any>();
  mapGroupBasket(
    id: string,
    basket: GroupBookingBasket
  ): BehaviorSubject<GroupBookingBasket> {
    if (!this.groupBaskets[id]) {
      this.groupBaskets[id] = new BehaviorSubject<GroupBookingBasket>(basket);
    } else {
      this.groupBaskets[id].next(basket);
    }
    return this.groupBaskets[id];
  }

  private initialize() {
    this.user$ = new BehaviorSubject<UserInfo>(this.initialUserInfo);
    this.basket$ = new BehaviorSubject<BasketData>(null);
    this.userAddresses$ = new BehaviorSubject<AddressBookUserAddress[]>(null);
    this.subscriptionStatus$ = new BehaviorSubject<UserSubscriptionStatus>(
      null
    );
    this.loggedIn$ = new BehaviorSubject<boolean>(false);
    this.groupBaskets = {};
    this.userData$ = new BehaviorSubject<UserData>(null);
    this.countries$ = new BehaviorSubject<CountryData[]>(null);
    this.impersonationStateChanged.subscribe(() => {
      Object.keys(this.groupBaskets).forEach((key) => {
        this.groupBaskets[key]?.complete();
        this.groupBaskets[key]?.unsubscribe();
      });
      this.groupBaskets = {};
    });
  }

  resetUserData(user: UserData) {
    // this.user$.next();
    // this.basket$.next(null);
    // this.userAddresses$.next(null);
    this.userData$.next(user);
    this.groupBaskets = {};
  }
}

export const AppState = new AppStateData();
