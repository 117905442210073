<div class="fixed-bottom bg-white border-top" *ngIf="shown">
  <div class="container">
    <div class="row">
      <div class="col" *ngIf="isImpersonating">
        <div class="p-2">
          Currently impersonating: {{ impersonatingEmail }}

          <button
            *ngIf="isImpersonating"
            class="btn btn-sm btn-primary ms-2"
            (click)="cancelImpersonation()"
          >
            Cancel impersonation
          </button>
        </div>
      </div>
      <div class="col text-end">
        <div class="p-2">
          <button
            *ngIf="!isImpersonating"
            class="btn btn-sm btn-primary"
            (click)="showImpersonateModal()"
          >
            Impersonate User
          </button>
          <button class="btn btn-sm btn-secondary ms-2" (click)="hideBar()">
            Hide Impersonation Menu
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!shown">
  <span
    class="p-2 px-3 bg-success text-white h5 m-0 fixed-bottom border"
    style="right: auto"
    *ngIf="isImpersonating"
  >
    Currently impersonating: {{ impersonatingEmail }}
  </span>
  <button
    class="btn btn-sm btn-secondary fixed-bottom"
    style="left: auto"
    (click)="showBar()"
  >
    Show Impersonation Menu
  </button>
</ng-container>
