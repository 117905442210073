import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserData } from '../../../routed-features/my-account/dtos/user-data';
import { StorageService } from '../../auth/services/storage-service';
import { UserService } from '../../auth/services/user.service';

const key = 'impersonated-user-data';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationDataService {
  constructor(private userService: UserService) {}
  private data = new BehaviorSubject<UserData>(this.loadFromLocalStorage());
  setData(userData: UserData, reload = true) {
    this.data.next(userData);
    StorageService.setItem(key, JSON.stringify(userData));
    if (reload) {
      window.location.reload();
    }
  }

  getData() {
    return this.data.value;
  }
  private loadFromLocalStorage(): UserData {
    if (StorageService.isSSR) {
      return null;
    }
    if (!this.userService.currentUserValue) {
      StorageService.removeItem(key);
      return null;
    }
    const data = StorageService.getItem(key);
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  }
}
