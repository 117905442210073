import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LegacyRedirectionService } from '../../services/legacy-redirection.service';

const nbPlusIds = [
  `a0K3N000000KO5UUAW`,
  `a0K1p00000ZHn4FEAT`,
  `a0K3N000000KO5PUAW`,
  `a0K1p00000ZHn40EAD`,
  `a0K3N000000KMMOUA4`,
  `a0K1p00000ZHn2KEAT`,
  `a0K3N000000KO5KUAW`,
  `a0K1p00000ZHn3bEAD`,
  `a0K3N000000KO5ZUAW`,
  `a0K1p00000ZHn4KEAT`,
];

@Component({
  selector: 'nbm-legacy-package-handler',
  templateUrl: './legacy-package-handler.component.html',
  styleUrls: ['./legacy-package-handler.component.scss'],
})
export class LegacyPackageHandlerComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private legacyRedirectionService: LegacyRedirectionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const id = (params.ID as string) || (params.id as string) || '';

      // if it's an nbplus post-sell we'll handle this explicitly
      if (nbPlusIds.some((x) => x === id)) {
        return this.legacyRedirectionService.redirectTo(`/nb-plus-fixed-term`);
      }

      this.legacyRedirectionService.getPackage(id).subscribe((result) => {
        if (result) {
          switch (result.type) {
            case 'Course':
              return this.legacyRedirectionService.redirectTo(
                `/face-to-face-courses/${result.slug}`
              );
            case 'Webinar':
              return this.legacyRedirectionService.redirectTo(
                `/webinars/${result.slug}`
              );
            case 'Book':
              return this.legacyRedirectionService.redirectTo(
                `/books/${result.slug}`
              );
            case 'AppraisalEssential':
              return this.legacyRedirectionService.redirectTo(
                `/appraisal-essentials/${result.slug}`
              );
          }
        }
        const type = this.activatedRoute.snapshot.data.type;
        switch (type) {
          case 'Course':
            return this.legacyRedirectionService.redirectTo(
              '/face-to-face-courses'
            );
          case 'Webinar':
            return this.legacyRedirectionService.redirectTo('/webinars');
          case 'Book':
            return this.legacyRedirectionService.redirectTo('/books');
          case 'AppraisalEssential':
            return this.legacyRedirectionService.redirectTo(
              '/appraisal-essentials'
            );
          default:
            return this.legacyRedirectionService.redirectTo('/');
        }
      });
    });
  }
}
